/**
 * @flow
 */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    FaUpload,
} from 'react-icons/lib/fa';
import ReactPaginate from 'react-paginate';

// Actions
import { getCurriculums } from '../actions/curriculum';
import { logout } from '../actions/user';

// Types
import { Curriculum } from '../types/curriculum';

type PropsType = {
    actions:{
        getCurriculums:() => void,
        logout:() => void,
    },
    curriculae:Array<Curriculum>
}

const pageSize = 10;

class CurriculumViewer extends React.Component {
    props:PropsType;
    state:{
        numberOfPages:number,
        currentPage:number,
        renderData:Array<Object>,
        filter:string,
    };

    constructor(props) {
        super(props);
        this.state = {
            numberOfPages: 0,
            currentPage: 0,
            filteredCurriculae: [],
            renderData: [],
            filter: '',
        };
    }

    componentDidMount() {
        this.props.actions.getCurriculums();
    }

    componentWillReceiveProps(nextProps:PropsType) {
        if (nextProps.curriculae.size !== this.props.curriculae.size) {
            this.setState({
                numberOfPages: Math.ceil(nextProps.curriculae.size / pageSize),
                currentPage: 0,
                filteredCurriculae: nextProps.curriculae.toArray(),
                renderData: nextProps.curriculae.toArray().slice(0, pageSize),
                filter: '',
            });
        }
    }

    handlePageClick = (data) => {
        const page = data.selected;
        const startIndex = page * pageSize;
        const endIndex = ((page + 1) * pageSize) > this.props.curriculae.size ?
            this.props.curriculae.size :
            ((page + 1) * (pageSize));

        this.setState({
            renderData: this.state.filteredCurriculae.slice(startIndex, endIndex),
            currentPage: page,
        });
    };

    download = async (firstName:string, lastName:string, downloadUrl:string, fileType:string) => {
        const response = await fetch(downloadUrl, { method: 'GET' });
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${firstName}${lastName}.${fileType}`;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 100);
    };

    handleFormSubmit = () => {
        this.props.actions.logout();
    };

    handleFilterChange = (event) => {
        this.setState({ filter: event.target.value.toLowerCase() });
    };

    handleFilterSubmit = (event) => {
        if (event.key === 'Enter') {
            this.applyFilter();
        }
    };

    applyFilter = () => {
        const filteredCurriculae = this.props.curriculae.filter((curriculum) => {
            return curriculum.firstName.toLowerCase().indexOf(this.state.filter) !== -1 ||
                curriculum.lastName.toLowerCase().indexOf(this.state.filter) !== -1 ||
                curriculum.course.toLowerCase().indexOf(this.state.filter) !== -1 ||
                curriculum.description.toLowerCase().indexOf(this.state.filter) !== -1 ||
                curriculum.speedinterview.toLowerCase().indexOf(this.state.filter) !== -1 ||
                curriculum.email.toLowerCase().indexOf(this.state.filter) !== -1;
        }).toArray();

        this.setState({
            filteredCurriculae,
            currentPage: 0,
            numberOfPages: Math.ceil(filteredCurriculae.length / pageSize),
            renderData: filteredCurriculae.slice(0, pageSize),
        });
    };

    render():ReactElement {
        return (
            <div className="Dashboard">
                <div className="container">
                    <div className="curriculumDisplay">
                        <div
                            className="columns"
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: 0,
                                marginTop: 10,
                            }}
                        >
                            <div className="column columns" style={{ marginBottom: 10, justifyContent: 'flex-start' }}>
                                <div className="column" style={{ flexGrow: 0 }}>
                                    <h1 style={{ fontSize: 33, fontWeight: 600 }}>Currículos</h1>
                                </div>
                                <div className="column" style={{ flexGrow: 0 }}>
                                    <a
                                        className="button"
                                        onClick={this.handleFormSubmit}
                                        style={{ marginTop: 10, color: '#32A9D6' }}
                                    >
                                        Logout
                                    </a>
                                </div>
                            </div>
                            <div className="column columns" style={{ marginBottom: 10, justifyContent: 'flex-start' }}>
                                <div className="column">
                                    <input
                                        onChange={this.handleFilterChange}
                                        onKeyPress={this.handleFilterSubmit}
                                        className="input"
                                        type="text"
                                        placeholder="Pesquisa"
                                    />
                                </div>
                            </div>
                        </div>
                        {this.props.curriculae.size > 0 &&
                        this.state.renderData.map((curriculum:Curriculum) =>
                            <div className="card" key={curriculum.id}>
                                <div className="card-content">
                                    <div className="media">
                                        <div className="media-content">
                                            <p className="title is-4">{curriculum.firstName} {curriculum.lastName}</p>
                                            <p className="subtitle is-6">{curriculum.course}</p>
                                            <p className="subtitle is-6">Email: {curriculum.email} | {curriculum.linkedIn &&
                                            <a href={curriculum.linkedIn}>LinkedIn</a>}</p>
                                            <p className="subtitle is-6">Speed Interview: {curriculum.speedinterview}</p>
                                        </div>
                                    </div>
                                    <div className="content" style={{ display: 'flex', flexDirection: 'column' }}>
                                        {curriculum.description}
                                        <a
                                            onClick={async () => {
                                                await this.download(
                                                    curriculum.firstName,
                                                    curriculum.lastName,
                                                    curriculum.downloadUrl,
                                                    curriculum.fileType,
                                                );
                                            }}
                                            className="button is-outlined"
                                            style={{ margin: 5, flexGrow: 0, alignSelf: 'flex-end' }}
                                        >
                                            <FaUpload/>&nbsp;
                                            <span>Descarregar Currículo</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>
                    {this.state.numberOfPages > 1 &&
                    <div style={{ margin: 15 }}>
                        <ReactPaginate
                            pageCount={this.state.numberOfPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            activeClassName="active"
                            containerClassName="pagination"
                            pageLinkClassName="pageLink"
                            pageClassName="page"
                            subContainerClassName="pages pagination"
                            disabledClassName="disabled"
                            forcePage={this.state.currentPage}
                        />
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state:State) => ({
    curriculae: state.curriculum.entities,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
    actions: bindActionCreators({
        getCurriculums,
        logout,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurriculumViewer);
