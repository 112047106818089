/**
 * @flow
 */
import { Record } from 'immutable';

class State extends Record({
    isSubmitting: false,
    error: null,
    submissionSuccessful: null,
}) {
    isSubmitting:boolean;
    error:string;
    submissionSuccessful:string;
}

export default State;