/**
 * @flow
 */
import { Dispatch } from 'redux';
import fetch from 'cross-fetch';

import type { Event } from '../types/events';

export const actionTypes = {
    ADD_EVENTS: 'EVENTS/ADD_EVENTS',
};

export const addEvents = (events:Event) => ({
    type: actionTypes.ADD_EVENTS,
    payload: events,
});

/**
 * Async Actions
 */

const CALENDAR_ID = 'b2s8h3eseh755es0f402cm4r48@group.calendar.google.com';
const API_KEY = 'AIzaSyDocV4Ldmsec6z2V9Ynaqie6paGOibwxlw';
const url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}`;

export const getEvents = () =>
    async (dispatch:Dispatch) => {
        const response = await fetch(url);

        const responseJson = await response.json();

        if (response.ok) {
            // Parse json
            const events = responseJson.items.map((item: Object) => {

                const body = item.description.trim().split('\n');

                return {
                    id: item.id,
                    start: new Date(item.start.dateTime),
                    end: new Date(item.end.dateTime),
                    title: item.summary,
                    type: body[0],
                    description: body[1],
                    imageUrl: body[2],
                    location: item.location,
                };
            });

            await dispatch(addEvents(events));
        }
    };
