/**
 * @flow
 */

// Imports
import type { Action } from 'redux';

import { Map } from 'immutable';
import { actionTypes } from '../actions/curriculum';
import State, { Curriculum } from '../types/curriculum';

/**
 * Alert reducer function.
 *
 * @param state Current state.
 * @param action Action to process.
 * @returns {*} Next state.
 */
const curriculumReducer = (state:?State = new State(), action:Action):State => {
    if (!(state instanceof State)) {
        return new State().mergeDeep(state);
    }

    switch (action.type) {
        case actionTypes.ADD_CURRICULUMS:
            const dataArray =
                Array.isArray(action.payload) ? action.payload : [action.payload];
            return state.mergeIn(
                ['entities'],
                Map(dataArray.map((object:Object):[string, Object] =>
                    [object.id, object instanceof Curriculum ? object : new Curriculum(object)])),
            );

        case actionTypes.CLEAR_CURRICULUMS:
            return state.set('entities', Map());

        case actionTypes.SET_SUBMISSION_ERROR:
            return state.set('submissionError', action.payload);

        case actionTypes.SET_SUBMITTING:
            return state.set('submitting', action.payload);

        case actionTypes.SET_SUBMISSION_SUCCESSFUL:
            return state.set('submissionSuccessful', action.payload);

        default:
            return state;
    }
};

export default curriculumReducer;
