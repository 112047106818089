/**
 * @flow
 */
import React from "react";
import { Link } from "react-router-dom";

import logoIST from "../resources/logoist-small.png";
import logoLAGE from "../resources/logoLAGE256x128.png";
import logoN3E from "../resources/logoN3E256x128.png";
import logoNEIIST from "../resources/logoNEIIST256x128.png";
import logoNEETI from "../resources/logoNEETI256x128.png";
import logoNEEGI from "../resources/logoNEEGI256x128.png";

const Footer = () => (
    <>
        <div className="footer-nucleos">
            <div className="container">
                <p className="is-uppercase is-size-5 has-text-centered has-text-weight-medium">
                    Núcleos
                </p>
                <div className="footer-logos">
                    <div className="columns is-mobile">
                        <div className="column flex-column has-text-centered">
                            <a
                                href="https://lage2.ist.utl.pt/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={logoLAGE} alt="alt logo" />
                            </a>
                        </div>

                        <div className="column flex-column has-text-centered">
                            <a
                                href="https://n3e.tecnico.ulisboa.pt/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={logoN3E} alt="N3E logo" />
                            </a>
                        </div>

                        <div className="column flex-column has-text-centered">
                            <a
                                href="https://neiist.tecnico.ulisboa.pt/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={logoNEIIST} alt="LAGE logo" />
                            </a>
                        </div>

                        <div className="column flex-column has-text-centered">
                            <a
                                href="https://neegi.ist.utl.pt/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={logoNEEGI} alt="NEEGI logo" />
                            </a>
                        </div>

                        <div className="column flex-column has-text-centered">
                            <a
                                href="https://neeti.tecnico.ulisboa.pt/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={logoNEETI} alt="NEETI logo" />
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="footer">
                    <div className="columns">
                        <div className="column is-half has-text-justified">
                            <div className="footer-heading is-size-5">
                                Estrutura da SET
                            </div>
                            <div className="footer-subheading">Anfitrião:</div>
                            <span>
                                <img src={logoIST} alt="IST logo" /> Instituto Superior Técnico
                            </span>
                            <div className="footer-subheading">
                                Organizador:
                            </div>
                            <span>
                                <img src={logoLAGE} alt="LAGE logo"/> Lab. de Apoio à Gestão de
                                Atividades EC. dos Estudantes
                            </span>
                            <div className="footer-subheading">Apoios:</div>
                            <span>
                                <img src={logoN3E} alt="N3E logo"/> Núcleo de Estudantes de
                                Engenharia Eletrónica
                            </span>
                            <span>
                                <img src={logoNEIIST} alt="NEIIST logo"/> Núcleo Estudantil de
                                Informática do IST
                            </span>
                            <span>
                                <img src={logoNEEGI} alt="NEEGI logo"/> Núcleo de Estudantes de
                                Engenharia e Gestão Industrial
                            </span>
                            <span>
                                <img src={logoNEETI} alt="NEETI logo"/> Núcleo de Estudantes de
                                Engenharia de Telecomunicações e Informática
                            </span>
                        </div>

                        <div className="column">
                            <div className="footer-heading is-size-5 is-uppercase">
                                Links do Site
                            </div>
                            <div className="footer-subheading">
                                <Link to="/">Página inicial</Link>
                            </div>
                            <div className="footer-subheading">
                                <Link to="/programa">Programa</Link>
                            </div>
                            <div className="footer-subheading">
                                <Link to="/empresas">Empresas</Link>
                            </div>
                            <div className="footer-subheading">
                                <Link to="/atividades">Atividades</Link>
                            </div>
                            <div className="footer-subheading">
                                <Link to="/equipa">Equipa</Link>
                            </div>
                            <div className="footer-subheading">
                                <Link to="/premios">Prémios</Link>
                            </div>
                            <div className="footer-subheading">
                                <Link to="/contactos">Contacte-nos</Link>
                            </div>
                        </div>

                        <div className="column">
                            <div className="footer-heading is-size-5 is-uppercase">
                                Ajuda-nos
                            </div>
                            <div className="footer-subheading">
                                <Link to="/reportar">Reportar bug</Link>
                            </div>
                            <div className="footer-subheading">
                                <Link to="/opiniao">Dar a sua opinião</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-footer">
            <div className="columns">
                <div className="column has-text-centered">
                    <p>
                        Copyright &copy; 2017 - 2022 LAGE2. Todos os Direitos
                        Reservados.
                    </p>
                </div>
            </div>
        </div>
    </>
);

export default Footer;
