/**
 * @flow
 */
import React, { createRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FaUpload } from "react-icons/lib/fa";
import Spinner from "react-spinkit";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Actions
import { submitCurriculum } from "../actions/curriculum";

type PropsType = {
    actions: {
        submitCurriculum: (formData: Object) => void,
    },
    submitting: boolean,
    submissionError: string,
    submissionSuccessful: string,
};

gsap.registerPlugin(ScrollTrigger);

class Students extends React.Component {
    props: PropsType;
    state = {
        firstName: "",
        lastName: "",
        email: "",
        course: "Engenharia Informática e de Computadores",
        linkedIn: "",
        description: "",
        speedinterview: "Sim",
        file: "",
        formErrors: {},
    };

    formRef = createRef(null);
    submissionRef = createRef(null);
    titlesRef = createRef(null);

    constructor(props) {
        super(props);
        this.titlesRef.current = [];
    }

    componentDidMount() {
        gsap.fromTo(
            this.formRef.current,
            {
                y: 50,
                alpha: 0,
            },
            {
                y: 0,
                alpha: 1,
                duration: 0.5,
                scrollTrigger: {
                    trigger: this.formRef.current,
                    start: "top center",
                    end: "bottom top",
                },
            }
        );

        gsap.fromTo(
            this.submissionRef.current,
            {
                y: 50,
                alpha: 0,
            },
            {
                y: 0,
                alpha: 1,
                duration: 0.5,
                scrollTrigger: {
                    trigger: this.submissionRef.current,
                    start: "top center",
                    end: "bottom top",
                },
            }
        );

        this.titlesRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    x: 100,
                    alpha: 0,
                },
                {
                    x: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=100",
                        end: "bottom top",
                    },
                }
            );
        });
    }

    addToTitlesRef = el => {
        if (el && !this.titlesRef.current.includes(el)) {
            this.titlesRef.current.push(el);
        }
    };

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.submissionSuccessful !==
                this.props.submissionSuccessful &&
            nextProps.submissionSuccessful !== null &&
            nextProps.submissionSuccessful.length > 0
        ) {
            this.setState({
                firstName: "",
                lastName: "",
                email: "",
                course: "Engenharia Eletrónica",
                linkedIn: "",
                description: "",
                speedinterview: "Não",
                file: "",
                formErrors: {},
            });
            document
                .getElementById("courseCustomInput")
                .classList.add("is-invisible");
        }
    }

    handleFirstNameChange = event => {
        this.setState({ firstName: event.target.value });
    };

    handleLastNameChange = event => {
        this.setState({ lastName: event.target.value });
    };

    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    };

    handleCourseChange = event => {
        this.setState({ course: event.target.value });
        if (event.target.value === "Outros") {
            document
                .getElementById("courseCustomInput")
                .classList.remove("is-invisible");
            this.setState({ course: "" });
        } else {
            document
                .getElementById("courseCustomInput")
                .classList.add("is-invisible");
        }
    };

    handleCourseChangeCustom = event => {
        this.setState({ course: event.target.value });
    };

    handleLinkedInChange = event => {
        this.setState({ linkedIn: event.target.value });
    };

    handleDescriptionChange = event => {
        this.setState({ description: event.target.value });
    };

    handleSpeedInterviewChange = event => {
        this.setState({ speedinterview: event.target.value });
    };

    handleFileUpload = event => {
        this.setState({ file: event.target.files[0] });
    };

    validateForm = () => {
        const formErrors = {};

        if (!this.state.firstName || this.state.firstName.length === 0) {
            formErrors.firstName = "⚠️ Campo obrigatório";
        }

        if (!this.state.lastName || this.state.lastName.length === 0) {
            formErrors.lastName = "⚠️ Campo obrigatório";
        }

        if (!this.state.email || this.state.email.length === 0) {
            formErrors.email = "⚠️ Campo obrigatório";
        }

        if (
            !this.state.email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
            formErrors.email = "⚠️ Endereço de email inválido";
        }

        if (!this.state.file) {
            formErrors.file = "⚠️ Não carregado";
        }

        this.setState({ formErrors });
        return Object.keys(formErrors).length === 0;
    };

    handleFormSubmit = () => {
        if (this.validateForm()) {
            if (this.state.course.trim() === "")
                this.setState({ course: "Outros" });
            this.props.actions.submitCurriculum(this.state);
        }
    };

    render() {
        return (
            <div className="Students">
                <section id="about-section">
                    <div
                        className="container about-box"
                        style={{ padding: 50, paddingBottom: 25 }}
                    >
                        <div
                            className="box-title-container"
                            ref={this.addToTitlesRef}
                        >
                            <p>Submete o teu currículo</p>
                        </div>
                        {/* <p className="help is-danger">⚠️ De momento existe um problema com submissões feitas através do Safari,
                            por favor utilize outro browser se for o caso.</p> */}
                        <div ref={this.formRef}>
                            <div className="columns">
                                <div className="column field">
                                    <label className="label">Nome</label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            value={this.state.firstName}
                                            onChange={
                                                this.handleFirstNameChange
                                            }
                                            type="text"
                                            placeholder="Nome"
                                        />
                                    </div>
                                    {this.state.formErrors.firstName && (
                                        <p className="help is-danger">
                                            {this.state.formErrors.firstName}
                                        </p>
                                    )}
                                </div>
                                <div className="column field">
                                    <label className="label">Apelido</label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            value={this.state.lastName}
                                            onChange={this.handleLastNameChange}
                                            type="text"
                                            placeholder="Apelido"
                                        />
                                    </div>
                                    {this.state.formErrors.lastName && (
                                        <p className="help is-danger">
                                            {this.state.formErrors.lastName}
                                        </p>
                                    )}
                                </div>
                                <div className="column field">
                                    <label className="label">
                                        Vais participar nas Speed Interviews?
                                    </label>
                                    <div className="control is-expanded">
                                        <div className="select is-fullwidth">
                                            <select
                                                value={
                                                    this.state.speedinterview
                                                }
                                                onChange={
                                                    this
                                                        .handleSpeedInterviewChange
                                                }
                                                name="speedinterview"
                                            >
                                                <option value="Não">Não</option>
                                                <option value="Sim">Sim</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column field">
                                    <label className="label">Email</label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            value={this.state.email}
                                            onChange={this.handleEmailChange}
                                            type="email"
                                            placeholder="Email"
                                        />
                                    </div>
                                    {this.state.formErrors.email && (
                                        <p className="help is-danger">
                                            {this.state.formErrors.email}
                                        </p>
                                    )}
                                </div>
                                <div className="column field">
                                    <label className="label">Curso</label>
                                    <div className="control is-expanded">
                                        <div className="select is-fullwidth">
                                            <select
                                                value={
                                                    this.state.course !==
                                                        "Engenharia Eletrónica" &&
                                                    this.state.course !==
                                                        "Engenharia e Gestão Industrial" &&
                                                    this.state.course !==
                                                        "Engenharia de Telecomunicações e Informática" &&
                                                    this.state.course !==
                                                        "Engenharia Informática e de Computadores"
                                                        ? "Outros"
                                                        : this.state.course
                                                }
                                                onChange={
                                                    this.handleCourseChange
                                                }
                                                name="curso"
                                            >
                                                <option value="Engenharia Eletrónica">
                                                    Engenharia Eletrónica
                                                </option>
                                                <option value="Engenharia e Gestão Industrial">
                                                    Engenharia e Gestão
                                                    Industrial
                                                </option>
                                                <option value="Engenharia de Telecomunicações e Informática">
                                                    Engenharia de
                                                    Telecomunicações e
                                                    Informática
                                                </option>
                                                <option value="Engenharia Informática e de Computadores">
                                                    Engenharia Informática e de
                                                    Computadores
                                                </option>
                                                <option value="Outros">
                                                    Outros
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column field">
                                    <label className="label">LinkedIn</label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            value={this.state.linkedIn}
                                            onChange={this.handleLinkedInChange}
                                            type="text"
                                            placeholder="LinkedIn"
                                        />
                                    </div>
                                    {this.state.formErrors.linkedIn && (
                                        <p className="help is-danger">
                                            {this.state.formErrors.linkedIn}
                                        </p>
                                    )}
                                </div>
                                <div
                                    id="courseCustomInput"
                                    className="column field is-invisible"
                                >
                                    <label className="label">Qual?</label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            value={this.state.course}
                                            onChange={
                                                this.handleCourseChangeCustom
                                            }
                                            type="text"
                                            placeholder=""
                                        />
                                    </div>
                                    {this.state.formErrors.linkedIn && (
                                        <p className="help is-danger">
                                            {this.state.formErrors.linkedIn}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column field">
                                    <label className="label">
                                        Descreve-te (Opcional)
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            value={this.state.description}
                                            onChange={
                                                this.handleDescriptionChange
                                            }
                                            placeholder="Faz uma breve introdução ..."
                                        />
                                    </div>
                                </div>
                                <div
                                    className="column field"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    <div className="file is-medium is-boxed has-name">
                                        <label className="file-label">
                                            <input
                                                className="file-input"
                                                onChange={this.handleFileUpload}
                                                type="file"
                                                name="resume"
                                            />
                                            <span className="file-cta">
                                                <span className="file-icon">
                                                    <FaUpload />
                                                </span>
                                                <span className="file-label">
                                                    Carrega o teu CV
                                                </span>
                                            </span>
                                            {this.state.file && (
                                                <span className="file-name">
                                                    {this.state.file.name}
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                    {this.state.formErrors.file && (
                                        <p className="help is-danger">
                                            {this.state.formErrors.file}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                className="columns"
                                style={{
                                    marginBottom: 10,
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div className="column">
                                    {this.props.submissionSuccessful != null &&
                                        this.props.submissionSuccessful.length >
                                            0 && (
                                            <div className="notification is-primary">
                                                Currículo submetido com sucesso!
                                            </div>
                                        )}
                                    {this.props.submissionError != null &&
                                        this.props.submissionError.length >
                                            0 && (
                                            <div className="notification is-danger">
                                                Erro ao submeter o currículo,
                                                por favor tente outra vez!
                                            </div>
                                        )}
                                </div>
                                <span style={{ display: "flex" }}>
                                    Ao submeteres o teu currículo, estás a
                                    concordar com o nosso regulamento,
                                    apresentado em baixo.
                                </span>
                                <div className="column" style={{ flexGrow: 0 }}>
                                    {this.props.submitting ? (
                                        <Spinner
                                            name="circle"
                                            fadeIn="none"
                                            style={{
                                                color: "#009de0",
                                                width: 50,
                                                height: 50,
                                            }}
                                        />
                                    ) : (
                                        <div
                                            className="button"
                                            onClick={this.handleFormSubmit}
                                            style={{
                                                marginTop: 10,
                                                color: "#32A9D6",
                                            }}
                                        >
                                            Submeter
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="about-section" className="hero is-large">
                    <div className="container about-box">
                        <div
                            className="box-title-container"
                            ref={this.addToTitlesRef}
                        >
                            <p>Regulamento de Submissão de Currículos</p>
                        </div>
                        <div className="columns" ref={this.submissionRef}>
                            <div className="column about-column">
                                <h3 className="title about-title">
                                    1. Objectivo
                                </h3>
                                <p>
                                    <b>a)</b> O presente documento tem por
                                    objectivo definir as condições para a
                                    submissão de currículos através da
                                    plataforma disponibilizada pela equipa da
                                    edição de 2022 do evento SET – Semana
                                    Empresarial e Tecnológica, a decorrer entre
                                    os dias 14 a 18 de Março de 2022 no campus
                                    Taguspark do Instituto Superior Técnico,
                                    sito em Avenida Professor Doutor Cavaco
                                    Silva, 2744 -016 Porto Salvo, Portugal.
                                </p>
                                <p> &nbsp;</p>
                                <h3 className="title about-title">
                                    2. Informações
                                </h3>
                                <p>
                                    <b>a)</b> O evento SET – Semana Empresarial
                                    e Tecnológica é organizado pelo núcleo LAGE2
                                    – Laboratório de Apoio à Gestão de
                                    Actividades Extracurriculares dos
                                    Estudantes, registado como associação
                                    MSSKILLSLAB – Núcleo de Apoio À Gestão de
                                    Actividades Extra-Curriculares dos
                                    Estudantes, com sede em Instituto Superior
                                    Técnico – Campus Taguspark, Avenida
                                    Professor Doutor Cavaco Silva, 2744-016
                                    Porto Salvo, Portugal.
                                </p>
                                <p>
                                    <b>b)</b> A plataforma de submissão de
                                    currículos do evento SET 2022 é uma
                                    plataforma onde participantes do evento SET
                                    2022 podem submeter os seus currículos, em
                                    formato digital PDF, para consulta das
                                    empresas que participam no mesmo evento. A
                                    plataforma está disponível em
                                    https://set.tecnico.ulisboa.pt/estudantes .
                                </p>
                                <p>
                                    <b>c)</b> O website do evento SET
                                    (https://set.tecnico.ulisboa.pt/) está
                                    hospedado nos servidores do Instituto
                                    Superior Técnico, localizados em Portugal.
                                </p>
                            </div>
                            <div className="column about-column">
                                <h3 className="title about-title">
                                    3. Condições do tratamento dos currículos e
                                    da plataforma
                                </h3>
                                <p>
                                    <b>a)</b> Os currículos submetidos, em
                                    formato digital PDF, estão alojados nos
                                    servidores do Instituto Superior Técnico,
                                    localizados em Portugal.
                                </p>
                                <p>
                                    <b>b)</b> Apenas empresas que participam na
                                    edição da SET de 2022 (divulgadas em tempo
                                    próprio em
                                    https://set.tecnico.ulisboa.pt/empresas) têm
                                    acesso à plataforma.
                                </p>
                                <p>
                                    <b>c)</b> A equipa do evento SET 2022
                                    fornece credenciais de acesso à plataforma a
                                    cada empresa, sendo que cada empresa se
                                    compromete a que a credencial dada seja
                                    apenas usada por colaboradores da empresa.
                                </p>
                                <p>
                                    <b>d)</b> Os currículos submetidos estarão
                                    disponíveis na plataforma durante um ano
                                    após a sua submissão, sendo que a equipa do
                                    evento SET reserva o direito a apagar os
                                    ficheiros submetidos antes do prazo.
                                </p>
                                <p>
                                    <b>e)</b> Cada empresa compromete-se a
                                    apagar o(s) currículo(s) descarregado(s) um
                                    ano após o seu descarregamento a partir da
                                    plataforma disponibilizada.
                                </p>

                                <p> &nbsp;</p>

                                <p> Porto Salvo, 20 de Janeiro de 2022 </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state: Object) => ({
    submitting: state.curriculum.submitting,
    submissionError: state.curriculum.submissionError,
    submissionSuccessful: state.curriculum.submissionSuccessful,
});

const mapDispatchToProps = (dispatch: Object) => ({
    actions: bindActionCreators(
        {
            submitCurriculum,
        },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Students);
