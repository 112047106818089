/**
 * @flow
 */
import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

/* Empresas Parceiros Técnico Lisboa */
import celfocusLogo from "../resources/companies/partners/celfocus.webp";
import deloitteLogo from "../resources/companies/partners/Deloitte.webp";
import nosLogo from "../resources/companies/partners/NOS.webp";
import thalesLogo from "../resources/companies/partners/Thales.webp";

/* Empresas Platina */
import avanadeLogo from "../resources/companies/platinum/Avanade.webp";
import bringGlobalLogo from "../resources/companies/platinum/Bring Global.webp";
import cellnexLogo from "../resources/companies/platinum/Cellnex.webp";
import collabLogo from "../resources/companies/platinum/Collab.webp";
import crossjoinSolutionsLogo from "../resources/companies/platinum/crossjoin.webp";
import fidelidadeLogo from "../resources/companies/platinum/fidelidade.webp";
import indieCampersLogo from "../resources/companies/platinum/indiecampers.webp";
import innovationMakersLogo from "../resources/companies/platinum/innovation makers.webp";
import kpmgLogo from "../resources/companies/platinum/KPMG.webp";
import primeITLogo from "../resources/companies/platinum/PrimeIT.svg";
import syoneLogo from "../resources/companies/platinum/Syone.webp";
import navigatorLogo from "../resources/companies/platinum/Navigator.webp";
import vtxrmLogo from "../resources/companies/platinum/VTXRM.webp";
import milleniumBcpLogo from "../resources/companies/platinum/millenium.webp";
import sapLogo from "../resources/companies/platinum/SAP.webp";
import unicreLogo from "../resources/companies/platinum/Unicre.webp";
import neyondLogo from "../resources/companies/platinum/neyond.webp";
import olxGroupLogo from "../resources/companies/platinum/OLX Group.webp";

/* Empresas Ouro */
import accentureLogo from "../resources/companies/gold/Accenture.webp";
import auchanLogo from "../resources/companies/gold/Auchan.webp";
import bnpLogo from "../resources/companies/gold/BNP.webp";
import cgiLogo from "../resources/companies/gold/cgi.webp";
import credibomLogo from "../resources/companies/gold/Credibom.webp";
import eyLogo from "../resources/companies/gold/EY.webp";
import hccmConsultingLogo from "../resources/companies/gold/HCCM Consulting.webp";
import innowaveLogo from "../resources/companies/gold/Innowave.webp";
import incubadoraLogo from "../resources/companies/gold/Incubadora.webp";
import noesisLogo from "../resources/companies/gold/Noesis.webp";
import nttDataLogo from "../resources/companies/gold/NTT Data.webp";
import pwcLogo from "../resources/companies/gold/PwC.webp";
import smartConsultingLogo from "../resources/companies/gold/SmartConsulting.webp";
import visionBoxLogo from "../resources/companies/gold/Vision Box.webp";
import wortenLogo from "../resources/companies/gold/Worten.webp";
import bpiLogo from "../resources/companies/sponsors/BPI.webp";

/* Empresas Prata */
import innotechLogo from "../resources/companies/silver/innotech.webp";
import techFirmLogo from "../resources/companies/silver/tech-firm.webp";

//  Parceiros IST
const parceirosIST = [
    {
        name: "Celfocus",
        link: "htpps://ww.celfocus.com/",
        src: celfocusLogo,
    },
    {
        name: "Deloitte",
        link: "https://deloitte.com/",
        src: deloitteLogo,
    },
    {
        name: "Thales",
        link: "https://www.thalesgroup.com/",
        src: thalesLogo,
    },
    {
        name: "NOS",
        link: "https://www.nos.pt/",
        src: nosLogo,
    },
];

//  Parceiros Platina
const parceirosPlatina = [
    {
        name: "Avanade",
        link: "https://www.avanade.com/",
        src: avanadeLogo,
    },
    {
        name: "Bring Global",
        link: "https://bringglobal.com/",
        src: bringGlobalLogo,
    },
    {
        name: "Cellnex Portugal",
        link: "https://www.cellnextelecom.pt/",
        src: cellnexLogo,
    },
    {
        name: "Collab",
        link: "https://collab.com/pt-br/",
        src: collabLogo,
    },
    {
        name: "Crossjoin Solutions",
        link: "https://www.crossjoin.pt/?lang=pt-pt",
        src: crossjoinSolutionsLogo,
    },
    {
        name: "Fidelidade",
        link: "https://www.fidelidade.pt/",
        src: fidelidadeLogo,
    },
    {
        name: "Indie Campers",
        link: "https://indiecampers.pt/",
        src: indieCampersLogo,
    },
    {
        name: "Innovation Makers - Serviços de Consultoria em Inovação, Lda.",
        link: "https://www.inm.pt/",
        src: innovationMakersLogo,
    },
    {
        name: "KPMG",
        link: "https://home.kpmg/pt/pt/home.html",
        src: kpmgLogo,
    },
    {
        name: "Millenium Bcp",
        link: "https://ind.millenniumbcp.pt/",
        src: milleniumBcpLogo,
    },
    {
        name: "NEYOND",
        link: "https://www.neyond.pt/",
        src: neyondLogo,
    },
    {
        name: "PrimeIT Consulting",
        link: "https://www.primeit.pt/",
        src: primeITLogo,
    },
    {
        name: "SAP",
        link: "https://www.sap.com/portugal/index.html",
        src: sapLogo,
    },
    {
        name: "Syone",
        link: "https://www.syone.com/",
        src: syoneLogo,
    },
    {
        name: "The Navigator",
        link: "http://www.thenavigatorcompany.com/",
        src: navigatorLogo,
    },
    {
        name: "UNICRE",
        link: "https://www.unicre.pt/",
        src: unicreLogo,
    },
    {
        name: "VTXRM",
        link: "https://www.vtxrm.com/pt-pt/",
        src: vtxrmLogo,
    },
    {
        name: "OLX Group",
        link: "https://www.olxgroup.com/",
        src: olxGroupLogo,
    },
];

//  Parceiros Ouro
const parceirosOuro = [
    {
        name: "Accenture",
        link: "https://www.accenture.com/",
        src: accentureLogo,
    },
    {
        name: "BNP Paribas",
        link: "http://www.bnportugal.gov.pt/",
        src: bnpLogo,
    },
    {
        name: "Auchan Retail",
        link: "https://www.auchan-retail.pt/",
        src: auchanLogo,
    },
    {
        name: "BPI",
        link: "https://www.bancobpi.pt/particulares",
        src: bpiLogo,
    },
    {
        name: "CGI",
        link: "https://www.cgi.com/portugal/pt-pt",
        src: cgiLogo,
    },
    {
        name: "Credibom",
        link: "https://www.ey.com/pt_pt",
        src: credibomLogo,
    },
    {
        name: "EY",
        link: "https://www.ey.com/pt_pt",
        src: eyLogo,
    },
    {
        name: "HCCM Consulting",
        link: "https://hccm.pt/",
        src: hccmConsultingLogo,
    },
    {
        name: "Incubadora",
        link: "https://www.taguspark.pt/pt-pt/solucoes/incubadora/",
        src: incubadoraLogo,
    },
    {
        name: "Innowave",
        link: "https://www.innowave.tech/",
        src: innowaveLogo,
    },
    {
        name: "Noesis",
        link: "https://www.noesis.pt/",
        src: noesisLogo,
    },
    {
        name: "NTT DATA",
        link: "https://pt.nttdata.com/",
        src: nttDataLogo,
    },
    {
        name: "PwC",
        link: "https://www.pwc.pt/",
        src: pwcLogo,
    },
    {
        name: "SmartConsulting",
        link: "https://www.smartconsulting.pt/splash/index.html",
        src: smartConsultingLogo,
    },
    {
        name: "Vision Box",
        link: "https://www.vision-box.com/",
        src: visionBoxLogo,
    },
    {
        name: "Worten",
        link: "https://www.worten.pt/",
        src: wortenLogo,
    },
];

//  Parceiros Prata
const parceirosPrata = [
    {
        name: "Innotech",
        link: "https://www.innotech.pt/",
        src: innotechLogo,
    },
    {
        name: "Tech Firm",
        link: "https://bubble-go.ch/",
        src: techFirmLogo,
    },
];

//  Patrocinios
const patrocinios = [
    {
        name: "BPI",
        link: "https://www.bancobpi.pt/",
        src: bpiLogo,
    },
];

gsap.registerPlugin(ScrollTrigger);

const Companies = () => {
    const parceirosTecnicoRef = useRef([]);
    const parceirosPlatinaRef = useRef([]);
    const parceirosOuroRef = useRef([]);
    const parceirosPrataRef = useRef([]);
    const patrocioniosRef = useRef([]);
    const boxTextsRef = useRef([]);

    useEffect(() => {
        parceirosTecnicoRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=50",
                        end: "bottom top",
                    },
                }
            );
        });

        parceirosPlatinaRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=50",
                        end: "bottom top",
                    },
                }
            );
        });
        parceirosOuroRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=50",
                        end: "bottom top",
                    },
                }
            );
        });
        parceirosPrataRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=50",
                        end: "bottom top",
                    },
                }
            );
        });
        patrocioniosRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=50",
                        end: "bottom top",
                    },
                }
            );
        });

        boxTextsRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    x: 100,
                    alpha: 0,
                },
                {
                    x: 0,
                    alpha: 1,
                    duration: 0.5,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: r,
                        start: "top center",
                        end: "bottom top",
                    },
                }
            );
        });
    }, [
        parceirosTecnicoRef,
        parceirosPlatinaRef,
        parceirosOuroRef,
        parceirosPrataRef,
        patrocioniosRef,
        boxTextsRef,
    ]);

    const addToParceirosTecnicoRefs = el => {
        if (el && !parceirosTecnicoRef.current.includes(el)) {
            parceirosTecnicoRef.current.push(el);
        }
    };

    const addToParceirosPlatinaRefs = el => {
        if (el && !parceirosPlatinaRef.current.includes(el)) {
            parceirosPlatinaRef.current.push(el);
        }
    };

    const addToParceirosOuroRefs = el => {
        if (el && !parceirosOuroRef.current.includes(el)) {
            parceirosOuroRef.current.push(el);
        }
    };

    const addToParceirosPrataRefs = el => {
        if (el && !parceirosPrataRef.current.includes(el)) {
            parceirosPrataRef.current.push(el);
        }
    };

    const addToPatrociniosRefs = el => {
        if (el && !patrocioniosRef.current.includes(el)) {
            patrocioniosRef.current.push(el);
        }
    };

    const addToBoxTextsRefs = el => {
        if (el && !boxTextsRef.current.includes(el)) {
            boxTextsRef.current.push(el);
        }
    };

    return (
        <div className="Companies">
            <section className="hero is-large">
                <div className="container box">
                    <div
                        className="box-title-container"
                        ref={addToBoxTextsRefs}
                    >
                        <p>Parceiros Técnico Lisboa</p>
                    </div>
                    <div className="columns is-multiline is-centered is-mobile">
                        {parceirosIST.map(parceiro => {
                            return (
                                <div
                                    className="column is-one-third-widescreen company-icon-column"
                                    ref={addToParceirosTecnicoRefs}
                                >
                                    <figure className="image company-icon">
                                        <a
                                            href={parceiro.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className="company-icon"
                                                src={parceiro.src}
                                                alt={parceiro.name}
                                            />
                                        </a>
                                    </figure>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            <section className="hero is-large">
                <div className="container box">
                    <div
                        className="box-title-container"
                        ref={addToBoxTextsRefs}
                    >
                        <p>Patrocinadores Platina</p>
                    </div>
                    <div className="columns is-multiline is-centered is-mobile">
                        {parceirosPlatina.map(parceiro => {
                            return (
                                <div
                                    className="column is-one-third-widescreen company-icon-column"
                                    ref={addToParceirosPlatinaRefs}
                                >
                                    <figure className="image company-icon">
                                        <a
                                            href={parceiro.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className="company-icon"
                                                src={parceiro.src}
                                                alt={parceiro.name}
                                            />
                                        </a>
                                    </figure>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            <section className="hero is-large">
                <div className="container box">
                    <div
                        className="box-title-container"
                        ref={addToBoxTextsRefs}
                    >
                        <p>Patrocinadores Ouro</p>
                    </div>
                    <div className="columns is-multiline is-centered is-mobile">
                        {parceirosOuro.map(parceiro => {
                            return (
                                <div
                                    className="column is-one-third-widescreen company-icon-column"
                                    ref={addToParceirosOuroRefs}
                                >
                                    <figure className="image company-icon">
                                        <a
                                            href={parceiro.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className="company-icon"
                                                src={parceiro.src}
                                                alt={parceiro.name}
                                            />
                                        </a>
                                    </figure>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            <section className="hero is-large">
                <div className="container box">
                    <div
                        className="box-title-container"
                        ref={addToBoxTextsRefs}
                    >
                        <p>Patrocinadores Prata</p>
                    </div>
                    <div className="columns is-multiline is-centered is-mobile">
                        {parceirosPrata.map(parceiro => {
                            return (
                                <div
                                    className="column is-one-third-widescreen company-icon-column"
                                    ref={addToParceirosPrataRefs}
                                >
                                    <figure className="image company-icon">
                                        <a
                                            href={parceiro.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className="company-icon"
                                                src={parceiro.src}
                                                alt={parceiro.name}
                                            />
                                        </a>
                                    </figure>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            <section className="hero is-large">
                <div className="container box">
                    <div
                        className="box-title-container"
                        ref={addToBoxTextsRefs}
                    >
                        <p>Patrocínios</p>
                    </div>
                    <div className="columns is-multiline is-centered is-mobile">
                        {patrocinios.map(patrocinio => {
                            return (
                                <div
                                    className="column is-one-third-widescreen company-icon-column"
                                    ref={addToPatrociniosRefs}
                                >
                                    <figure className="image company-icon">
                                        <a
                                            href={patrocinio.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className="company-icon"
                                                src={patrocinio.src}
                                                alt={patrocinio.name}
                                            />
                                        </a>
                                    </figure>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Companies;

/*
    <div className="Companies">
        <div className="hero is-halfheight under-construction">
            <div className="hero-body ">
                <div className="container has-text-centered ">
                    <p className="title is-2 is-spaced has-text-white">
                        Estamos a contactar as empresas
                    </p>
                    <p className="subtitle has-text-white">
                        Se é uma empresa e quer fazer parte desta
                        semana empresarial, entre em contacto
                        connosco. Mande-nos um email para o{" "}
                        <i className="has-text-info">
                            public.relations@set-tagus.tecnico.ulisboa.pt
                        </i>{" "}
                        com o assunto{" "}
                        <i className="has-text-info">
                            SET - Parceria
                        </i>
                        .
                    </p>
                </div>
            </div>
        </div>
    </div>
*/
