/**
 * @flow
 */
import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";

import "../stylesheets/css/main.css";

// Components
import FloatingContactButtons from "../components/FloatingContactButtons";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Pages
import Home from "./Home";
import CheckIn from "./CheckIn";
import CheckInCompanies from "./CheckInCompanies";
import Schedule from "./Schedule";
import Companies from "./Companies";
import Speakers from "./Speakers";
import Students from "./Students";
import Team from "./Team";
import Contacts from "./Contacts";
import Prizes from "./Prizes";
import CurriculumLogin from "./CurriculumLogin";
import CurriculumDashboard from "./CurriculumDashboard";
import PrivateRoute from "../components/PrivateRoute";
import NotFound from "./NotFound";
import { connect } from "react-redux";

// Lib
import ScrollToTop from "../lib/ScrollToTop";
import history from "../lib/history";

// Trackers
import RouteChangeTracker from "./RouteChangeTracker";
import ReactGA from "react-ga";

const TRACKING_ID = "G-NZFQZJKY44";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  props: { token: string };
  state = {
    isActive: false,
  };

  onClickBurger = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  isAuthenticated = () => this.props.token != null;

  render(): ReactElement {
    return (
      <Router history={history}>
        <div>
          <ScrollToTop />
          <RouteChangeTracker />
          <FloatingContactButtons />
          <Navbar
            isActive={this.state.isActive}
            onClickBurger={this.onClickBurger}
            canClick={false} // CHANGE TO 'TRUE' THIS TO BE NAVIGABLE
          />
          <Switch>
            <Route exact path="/" component={Home} />

            {/* <Route exact path="/programa" component={Schedule} />
                    <Route exact path="/equipa" component={Team} />
                    <Route exact path="/empresas" component={Companies} />
                    <Route exact path="/atividades" component={Speakers} />
                    <Route exact path="/premios" component={Prizes} />
                    <Route exact path="/estudantes" component={Students} />
                    <Route exact path="/checkin" component={CheckIn} />
                    <Route
                        exact
                        path="/checkin/empresas"
                        component={CheckInCompanies}
                    />
                    <Route
                        exact
                        path="/curriculos/login"
                        component={CurriculumLogin}
                    />
                    <PrivateRoute
                        authed={this.isAuthenticated}
                        exact
                        path="/curriculos/dashboard"
                        component={CurriculumDashboard}
                    />  */}
            <Route exact path="/contactos" component={Contacts} />
            <Route path="*" component={NotFound} />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state: State) => ({
  token: state.user.token,
});

export default connect(mapStateToProps)(App);
