import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
    <div className="NotFound">
        <div className="hero is-fullheight banner">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <span>
                        <h1 className="is-size-1 ">
                            <span role="img">
                                <span role="img" aria-label="Warning">
                                    ⚠️
                                </span>
                            </span>
                        </h1>
                        <h2 className="has-text-weight-bold is-size-2 has-text-white">
                            Estás a tentar a uma página inexistente
                        </h2>
                        <h3 className="is-size-3 has-text-white">
                            Por favor, volta para trás ou{" "}
                            <Link exact to="/">
                                clica aqui
                            </Link>
                            .
                        </h3>
                    </span>
                </div>
            </div>
        </div>
    </div>
);

export default NotFound;
