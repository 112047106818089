/**
 * @flow
 */
import { combineReducers } from 'redux';

import events from './events';
import curriculum from './curriculum';
import user from './user';
import checkin from './checkin';

const rootReducer = combineReducers({
    events,
    curriculum,
    user,
    checkin,
});

export default rootReducer;
