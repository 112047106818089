/**
 * @flow
 */
import { Dispatch } from 'redux';
import { Curriculum } from '../types/curriculum';
import { logout } from './user';

export const actionTypes = {
    ADD_CURRICULUMS: 'CURRICULUM/ADD_CURRICULUMS',
    CLEAR_CURRICULUMS: 'CURRICULUM/CLEAR_CURRICULUMS',
    SET_SUBMITTING: 'CURRICULUM/SET_SUBMITTING',
    SET_SUBMISSION_ERROR: 'CURRICULUM/SET_SUBMISSION_ERROR',
    SET_SUBMISSION_SUCCESSFUL: 'CURRICULUM/SET_SUBMISSION_SUCCESSFUL',
};

export const addCurriculums = (curriculums:Array<Curriculum>) => ({
    type: actionTypes.ADD_CURRICULUMS,
    payload: curriculums,
});

export const clearCurriculums = () => ({
    type: actionTypes.CLEAR_CURRICULUMS,
});

export const setSubmitting = (isSubmitting:boolean) => ({
    type: actionTypes.SET_SUBMITTING,
    payload: isSubmitting,
});

export const setSubmissionErrors = (submissionError:string) => ({
    type: actionTypes.SET_SUBMISSION_ERROR,
    payload: submissionError,
});

export const setSubmissionSuccessful = (submissionSuccessful:string) => ({
    type: actionTypes.SET_SUBMISSION_SUCCESSFUL,
    payload: submissionSuccessful,
});

const uploadFileUrl = 'https://set.tecnico.ulisboa.pt/api/upload.php';
const uploadCVUrl = 'https://set.tecnico.ulisboa.pt/api/setStud.php';

export const submitCurriculum = (formData:Object) =>
    async (dispatch:Dispatch) => {
        try {
            await dispatch(setSubmissionErrors(null));
            await dispatch(setSubmissionSuccessful(null));
            await dispatch(setSubmitting(true));

            const data = new FormData();
            data.append('file', formData.file);

            let response = await fetch(uploadFileUrl, {
                method: 'post',
                body: data,
            });

            const responseJson = await response.json();

            if (response.ok) {
                const curriculumFileName = responseJson.generatedName;

                const object = {
                    newStudent: {
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        email: formData.email,
                        curso: formData.course,
                        description: formData.description,
                        speedinterview: formData.speedinterview,
                        curriculum: curriculumFileName,
                        linkedin: formData.linkedIn,
                    },
                };

                response = await fetch(uploadCVUrl, {
                    method: 'post',
                    body: JSON.stringify(object),
                });


                if (response.ok) {
                    await dispatch(setSubmissionSuccessful('Submetido com sucesso!'));
                } else {
                    await dispatch(setSubmissionErrors('Erro a submeter o formulário, por favor tente outra vez'));
                }
            } else {
                await dispatch(setSubmissionErrors('Erro a submeter o currículo, por favor tente outra vez'));
            }
        } catch (e) {
            await dispatch(setSubmissionErrors('Erro a submeter o currículo, por favor tente outra vez'));
        }

        await dispatch(setSubmitting(false));
    };

export const getCurriculums = () =>
    async (dispatch:Dispatch, getState:() => State) => {
        try {
            const getCurriculumsUrl = `https://set.tecnico.ulisboa.pt/api/getStudents.php`;

            const response = await fetch(getCurriculumsUrl, {
                method: 'GET',
                headers: {
                    AppAuthorization: `Bearer ${getState().user.token}`,
                },
            });

            const responseJson = await response.json();

            if (response.ok) {
                const downloadUrl = 'https://set.tecnico.ulisboa.pt/api/downloadCurr.php';
                await dispatch(addCurriculums(responseJson.map((response:Object) => ({
                    firstName: response.firstname,
                    lastName: response.lastname,
                    course: response.curso,
                    email: response.email,
                    description: response.description,
                    id: response.id,
                    linkedIn: response.linkedin,
                    speedinterview: response.speedinterview,
                    fileType: response.curriculum.split('.')[response.curriculum.split('.').length - 1],
                    downloadUrl: `${downloadUrl}?filename=${response.curriculum}&studentName=${response.firstname}${response.lastname}`,
                }))));
            } else {
                await dispatch(logout());
            }
        } catch (e) {
            await dispatch(logout());
        }
    };
