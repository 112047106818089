/**
 * @flow
 */
import React from 'react';
import {
    IoSocialFacebook,
    IoSocialInstagram,
    IoSocialTwitter,
    IoSocialYoutube,
    IoEmail,
} from 'react-icons/lib/io';

const iconSize = 25;

const FloatingContactButtons = () => (
    <div className="FloatingContactButtons is-hidden-mobile">
        <div className="fab-container">
            <a href="https://www.facebook.com/SET.Tecnico">
                <div className="icon">
                    <IoSocialFacebook size={iconSize} />
                </div>
            </a>
            <a href="https://www.instagram.com/set.ist/">
                <div className="icon">
                    <IoSocialInstagram size={iconSize} />
                </div>
            </a>
            <a href="https://twitter.com/SET_ISTTagus">
                <div className="icon">
                    <IoSocialTwitter size={iconSize} />
                </div>
            </a>
            <a href="mailto:geral@set-tagus.tecnico.ulisboa.pt?Subject=%20">
                <div className="icon">
                    <IoEmail size={iconSize} />
            </div>
            </a>
            <a href="https://www.youtube.com/user/ISTTagusSET">
                <div className="icon">
                    <IoSocialYoutube size={iconSize} />
                </div>
            </a>
        </div>
    </div>
);

export default FloatingContactButtons;
