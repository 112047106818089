/**
 * @flow
 */
import { Dispatch } from 'redux';
import history from '../lib/history';
import { clearCurriculums } from './curriculum';

export const actionTypes = {
    SET_TOKEN: 'USER/SET_TOKEN',
    SET_ERROR: 'USER/SET_ERROR',
    SET_IS_SUBMITTING: 'USER/SET_IS_SUBMITTING',
};

export const setToken = (token:string) => ({
    type: actionTypes.SET_TOKEN,
    payload: token,
});

export const setError = (error:string) => ({
    type: actionTypes.SET_ERROR,
    payload: error,
});

export const setIsSubmitting = (isSubmitting:boolean) => ({
    type: actionTypes.SET_IS_SUBMITTING,
    payload: isSubmitting,
});

const loginUrl = 'https://set.tecnico.ulisboa.pt/api/authenticate.php';
// const loginUrl = 'http://localhost:3000/api/authenticate.php';

export const logout = () =>
    async (dispatch:Dispatch) => {
        localStorage.removeItem('token');
        await dispatch(clearCurriculums());
        await dispatch(setToken(null));
    };

export const login = (username:string, password:string) =>
    async (dispatch:Dispatch) => {
        try {
            await dispatch(setIsSubmitting(true));

            const body = {
                logUser: {
                    username,
                    password,
                },
            };

            const response = await fetch(loginUrl, {
                method: 'POST',
                body: JSON.stringify(body),
            });


            const responseJson = await response.json();
            if (response.ok) {
                localStorage.setItem('token', responseJson.token);

                await dispatch(setToken(responseJson.token));

                history.push('/curriculos/dashboard');
            } else {
                await dispatch(setError('Não foi possível efetuar o login, por favor tente outra vez'));
            }
        } catch (e) {
            await dispatch(setError('Não foi possível efetuar o login, por favor tente outra vez'));
        }

        await dispatch(setIsSubmitting(false));
    };
