/**
 * @flow
 */
import React from 'react';
import {
    IoSocialLinkedin,
} from 'react-icons/lib/io';

type PropsType = {
    color:string,
    containerClassName:string,
    group:string,
    name:string,
    portrait:Object,
    portraitClassName:string,
    role:string,
    teamLeader:boolean;
    titleContainerClassName:string,
    url:string,
}

const iconSize = 25;

const TeamMemberPortrait = (props:PropsType) => (
    <div className={props.containerClassName || ''}>
        <div style={{ paddingBottom: 10 }} className={props.titleContainerClassName || ''}>
            <h1>{props.name}</h1>
        </div>
        <figure className="image is-square figure">
            <img
                className={props.portraitClassName || ''}
                src={props.portrait}
                alt={props.name}
            />
        </figure>
        <div className={props.titleContainerClassName || ''}>
            { props.role ? <h1>{props.role}</h1> : null }
            { props.url
                ? <a href={props.url} rel="noopener noreferrer" target="_blank">
                    <div className="icon">
                        <IoSocialLinkedin size={iconSize} />
                    </div>
                  </a>
                : null
            }
            <div className="tagContainer">
                <span className={`tag ${props.color || 'transparent'}`}>{props.group || ''}</span>
            </div>
        </div>
    </div>
);

export default TeamMemberPortrait;
