/**
 * @flow
 */
import React from 'react';
import { applyMiddleware, createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import App from './containers/App';

import reducer from './reducers';

import { unregister as unregisterServiceWorker }  from './registerServiceWorker';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = { user: { token: localStorage.getItem('token') } };

const store = createStore(
    reducer,
    persistedState,
    composeEnhancers(applyMiddleware(thunk)),
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'),
);
unregisterServiceWorker();
