/**
 * @flow
 */

// Imports
import type { Action } from 'redux';
import { actionTypes } from '../actions/checkin';
import State from '../types/checkin';

/**
 * Alert reducer function.
 *
 * @param state Current state.
 * @param action Action to process.
 * @returns {*} Next state.
 */
const checkinReducer = (state:?State = new State(), action:Action):State => {
    if (!(state instanceof State)) {
        return new State().mergeDeep(state);
    }

    switch (action.type) {
        case actionTypes.SET_SUBMITTING:
            return state.set('isSubmitting', action.payload);
            
        case actionTypes.SET_SUBMISSION_ERROR:
            return state.set('error', action.payload);

        case actionTypes.SET_SUBMISSION_SUCCESSFUL:
            return state.set('submissionSuccessful', action.payload);            
        
        default:
            return state;
    }
};

export default checkinReducer;
