/**
 * @flow
 */
import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

//  Components
import FlipCard from "../components/FlipCard";

const prizes = [
    {
        name: "Coluna SONOS com Google Assistant",
        day: 14,
    },
    {
        name: "Nintendo Switch",
        day: 15,
    },
    {
        name: "Tablet LENOVO Tab P11",
        day: 16,
    },
    {
        name: "Disco externo 4TB Toshiba",
        day: 17,
    },
    {
        name: "Máquina de café Nespresso",
        day: 18,
    },
    {
        name: "Salto 5K Skydive em Évora",
        activity: "CV's",
    },
    {
        name: "Auriculares Bluetooth Sony",
        activity: "Speed Interviews",
    },
    {
        name: "Smartwatch",
        activity: "Workshops",
    },
    {
        name: "Viagem Surpresa - Toratora Jukebox",
        activity: "Evento",
    },
];

gsap.registerPlugin(ScrollTrigger);

const Prizes = () => {
    const titlesRef = useRef([]);
    const prizesRef = useRef([]);
    const howToRef = useRef([]);

    const addToTitlesRef = el => {
        if (el && !titlesRef.current.includes(el)) titlesRef.current.push(el);
    };

    const addToPrizesRef = el => {
        if (el && !prizesRef.current.includes(el)) prizesRef.current.push(el);
    };

    const addToHowToRef = el => {
        if (el && !howToRef.current.includes(el)) howToRef.current.push(el);
    };

    useEffect(() => {
        titlesRef.current.forEach(t => {
            gsap.fromTo(
                t,
                {
                    x: 100,
                    alpha: 0,
                },
                {
                    x: 0,
                    alpha: 1,
                    ease: "power2.out",
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: t,
                        start: "top center+=100",
                        end: "bottom top",
                    },
                }
            );
        })

        

        prizesRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=100",
                        end: "bottom top",
                    },
                }
            );
        });

        howToRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=100",
                        end: "bottom top",
                    },
                }
            );
        });
    }, [titlesRef, prizesRef, howToRef]);

    return (
        <div className="Home">
            <section id="about-section" className="hero is-large">
                <div className="container about-box">
                    <div className="columns">
                        <div className="column about-column">
                            <h1 className="title about-title" ref={addToTitlesRef}>
                                Os prémios que podes ganhar! <span role="img" aria-label="Trophy">🏆</span>
                            </h1>
                            <h4 ref={addToPrizesRef}>Passa por cima e descobre o prémio escondido <span role="img" aria-label="treasure">🏴‍☠️</span>! Descobre como podes ganhar todos estes prémios mais abaixo. <span role="img" aria-label="Go down">👇</span></h4>

                            <div className="columns is-mobile is-multiline is-centered" style={{padding: '20px'}}>
                                {prizes.map(prize => (
                                    <div className="column" ref={addToPrizesRef}>
                                        <FlipCard
                                            name={prize.name}
                                            day={prize.day}
                                            activity={prize.activity}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container about-box">
                    <div className="columns">
                        <div className="column about-column">
                            <h1 className="title about-title" ref={addToTitlesRef}>
                                Como posso ganhar os prémios?
                            </h1>
                            <div ref={addToHowToRef}>
                                <span className="title small-title">
                                    &#9658; Prémio do dia X<br />
                                </span>
                                <p className="about-text-padded">
                                    <b>Participa nas atividades do dia X:</b>
                                    <br />
                                    <span className="small-title">
                                        &emsp;&#x2714;
                                    </span>{" "}
                                    Networking com 3 empresas/núcleos diferentes{" "}
                                    <span
                                        className="small-title"
                                        style={{ color: "#009de0" }}
                                    >
                                        ➜
                                    </span>{" "}
                                    1 entrada
                                    <br />
                                    <span className="small-title">
                                        &emsp;&#x2714;
                                    </span>{" "}
                                    Workshop{" "}
                                    <span
                                        className="small-title"
                                        style={{ color: "#009de0" }}
                                    >
                                        ➜
                                    </span>{" "}
                                    1 entrada
                                    <br />
                                    <span className="small-title">
                                        &emsp;&#x2714;
                                    </span>{" "}
                                    Speed Interview{" "}
                                    <span
                                        className="small-title"
                                        style={{ color: "#009de0" }}
                                    >
                                        ➜
                                    </span>{" "}
                                    1 entrada
                                    <br />
                                    <span className="small-title">
                                        &emsp;&#x2714;
                                    </span>{" "}
                                    Palestra/Painel{" "}
                                    <span
                                        className="small-title"
                                        style={{ color: "#009de0" }}
                                    >
                                        ➜
                                    </span>{" "}
                                    3 entradas
                                    <br />
                                    <span className="small-title">
                                        &emsp;&#x2714;
                                    </span>{" "}
                                    Apresentação de empresa{" "}
                                    <span
                                        className="small-title"
                                        style={{ color: "#009de0" }}
                                    >
                                        ➜
                                    </span>{" "}
                                    2 entradas
                                    <br />
                                </p>
                            </div>
                            <div ref={addToHowToRef}>
                                <span className="title small-title">
                                    &#9658; Prémio Workshops
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    <span className="small-title">
                                        &emsp;&#x2714;
                                    </span>{" "}
                                    Para cada workshop em que participares{" "}
                                    <span
                                        className="small-title"
                                        style={{ color: "#009de0" }}
                                    >
                                        ➜
                                    </span>{" "}
                                    1 entrada
                                    <br />
                                </p>
                            </div>
                            <div ref={addToHowToRef}>
                                <span className="title small-title">
                                    &#9658; Prémio Speed Interviews
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    <span className="small-title">
                                        &emsp;&#x2714;
                                    </span>{" "}
                                    Participa na atividade{" "}
                                    <span
                                        className="small-title"
                                        style={{ color: "#009de0" }}
                                    >
                                        ➜
                                    </span>{" "}
                                    1 entrada
                                    <br />
                                </p>
                            </div>
                            <div ref={addToHowToRef}>
                                <span className="title small-title">
                                    &#9658; Prémio Submissão de CV
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    <span className="small-title">
                                        &emsp;&#x2714;
                                    </span>{" "}
                                    Submete o teu CV no nosso site{" "}
                                    <a href="./estudantes">aqui</a>{" "}
                                    <span
                                        className="small-title"
                                        style={{ color: "#009de0" }}
                                    >
                                        ➜
                                    </span>{" "}
                                    1 entrada
                                    <br />
                                </p>
                            </div>
                            <div ref={addToHowToRef}>
                                <span className="title small-title">
                                    &#9658; Prémio do Evento
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    <span className="small-title">
                                        &emsp;&#x2714;
                                    </span>{" "}
                                    Participa nas atividades dos dias 14, 15,
                                    16, 17 e 18! Todas as tuas entradas contarão
                                    para o prémio do evento. Quanto mais
                                    entradas tiveres, maior é a probabilidade de
                                    ganhares!
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Prizes;
