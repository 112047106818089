/**
 * @flow
 */
import _ from "lodash";
import React from "react";
import { OrderedMap } from "immutable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/pt";

//  Dia 14
import accentureLogo from "../resources/companies/gold/Accenture.webp";
import auchanLogo from "../resources/companies/gold/Auchan.webp";
import bnpLogo from "../resources/companies/gold/BNP.webp";
import innowaveLogo from "../resources/companies/gold/Innowave.webp";
import milleniumLogo from "../resources/companies/platinum/millenium.webp";
import collabLogo from "../resources/companies/platinum/Collab.webp";
import crossJoinLogo from "../resources/companies/platinum/crossjoin.webp";
import credibomLogo from "../resources/companies/gold/Credibom.webp";
import deloitteLogo from "../resources/companies/partners/Deloitte.webp";
import incubadoraLogo from "../resources/companies/gold/Incubadora.webp";
import cellnexLogo from "../resources/companies/platinum/Cellnex.webp";
import indieCampersLogo from "../resources/companies/platinum/indiecampers.webp";
import nosLogo from "../resources/companies/partners/NOS.webp";
import nttDataLogo from "../resources/companies/gold/NTT Data.webp";
import innoTechLogo from "../resources/companies/silver/innotech.webp";
import eyLogo from "../resources/companies/gold/EY.webp";
import bpiLogo from "../resources/companies/sponsors/BPI.webp";
import bubblesGoLogo from "../resources/companies/silver/tech-firm.webp";
import olxGroupLogo from "../resources/companies/platinum/OLX Group.webp";
import pwcLogo from "../resources/companies/gold/PwC.webp";

//  Dia 15
import unicreLogo from "../resources/companies/platinum/Unicre.webp";
import wortenLogo from "../resources/companies/gold/Worten.webp";
import kpmgLogo from "../resources/companies/platinum/KPMG.webp";
import fidelidadeLogo from "../resources/companies/platinum/fidelidade.webp";
import bringGlobalLogo from "../resources/companies/platinum/Bring Global.webp";
import innovationMakersLogo from "../resources/companies/platinum/innovation makers.webp";
import primeIT from "../resources/companies/platinum/PrimeIT.svg";

//  Dia 16
import vtxrmLogo from "../resources/companies/platinum/VTXRM.webp";
import avanadeLogo from "../resources/companies/platinum/Avanade.webp";
import sapLogo from "../resources/companies/platinum/SAP.webp";
import noesisLogo from "../resources/companies/gold/Noesis.webp";
import visionBoxLogo from "../resources/companies/gold/Vision Box.webp";
import hccmConsultingLogo from "../resources/companies/gold/HCCM Consulting.webp";
import navigatorLogo from "../resources/companies/platinum/Navigator.webp";
import syoneLogo from "../resources/companies/platinum/Syone.webp";
import neyondLogo from "../resources/companies/platinum/neyond.webp";
import thalesLogo from "../resources/companies/partners/Thales.webp";
import celfocusLogo from "../resources/companies/partners/celfocus.webp";

//  Dia 17
import smartConsultingLogo from "../resources/companies/gold/SmartConsulting.webp";
import cgiLogo from "../resources/companies/gold/cgi.webp";

/* logos nucleos */
import lageLogo from "../resources/studentGroups/logoLAGE.png";
import n3eLogo from "../resources/studentGroups/logoN3E.png";
import neiistLogo from "../resources/studentGroups/logoNEIIST.png";
import neetiLogo from "../resources/studentGroups/logoNEETI.png";
import neegiLogo from "../resources/studentGroups/logoNEEGI.png";
import fstLogo from "../resources/studentGroups/logoFst.png";
import gameDevLogo from "../resources/studentGroups/logoGameDev.png";
import IEEELogo from "../resources/studentGroups/logoIeee.png";
import junitecLogo from "../resources/studentGroups/logoJunitec.png";
import tmistLogo from "../resources/studentGroups/logoTmist.png";
import tsbLogo from "../resources/studentGroups/logoTsb.png";

import { getEvents } from "../actions/events";
import { EventMapType } from "../types/events";

moment.locale("pt-PT");

const days = [
    new Date("03/14/2022"),
    new Date("03/15/2022"),
    new Date("03/16/2022"),
    new Date("03/17/2022"),
    new Date("03/18/2022"),
];

const eventColor = OrderedMap()
    .set("Apresentação", "#93C571")
    .set("Workshop", "#ff6e4a")
    .set("Palestra", "#5b89bc")
    .set("Painel", "#5b89bc")
    .set("Atividades", "#d69d3a")
    .set("Comida", "#c362d7")
    .set("Stands", "#825ed4");

const companies = OrderedMap()
    .set(new Date("03/14/2022"), [
        accentureLogo,
        auchanLogo,
        bnpLogo,
        pwcLogo,
        innowaveLogo,
        milleniumLogo,
        collabLogo,
        crossJoinLogo,
        credibomLogo,
        deloitteLogo,
        incubadoraLogo,
        cellnexLogo,
        indieCampersLogo,
        nosLogo,
        nttDataLogo,
        innoTechLogo,
        eyLogo,
        bpiLogo,
        bubblesGoLogo,
        olxGroupLogo,
        
    ])
    .set(new Date("03/15/2022"), [
        accentureLogo,
        wortenLogo,
        innowaveLogo,
        milleniumLogo,
        collabLogo,
        crossJoinLogo,
        unicreLogo,
        deloitteLogo,
        incubadoraLogo,
        cellnexLogo,
        indieCampersLogo,
        kpmgLogo,
        fidelidadeLogo,
        bringGlobalLogo,
        nosLogo,
        innovationMakersLogo,
        primeIT,
    ])
    .set(new Date("03/16/2022"), [
        collabLogo,
        crossJoinLogo,
        unicreLogo,
        cellnexLogo,
        vtxrmLogo,
        innovationMakersLogo,
        kpmgLogo,
        fidelidadeLogo,
        bringGlobalLogo,
        sapLogo,
        noesisLogo,
        visionBoxLogo,
        avanadeLogo,
        hccmConsultingLogo,
        navigatorLogo,
        syoneLogo,
        neyondLogo,
        thalesLogo,
        celfocusLogo,
        primeIT,
    ])
    .set(new Date("03/17/2022"), [
        smartConsultingLogo,
        collabLogo,
        crossJoinLogo,
        cellnexLogo,
        vtxrmLogo,
        kpmgLogo,
        bringGlobalLogo,
        sapLogo,
        cgiLogo,
        avanadeLogo,
        innovationMakersLogo,
        syoneLogo,
        thalesLogo,
        celfocusLogo,
        primeIT,
    ])
    .set(new Date("03/18/2022"), [
        lageLogo,
        n3eLogo,
        neiistLogo,
        neetiLogo,
        neegiLogo,
        fstLogo,
        gameDevLogo,
        IEEELogo,
        junitecLogo,
        tmistLogo,
        tsbLogo
    ]);

type PropsType = {
    actions: {
        getEvents: () => void,
    },
    events: EventMapType,
};

class Schedule extends React.Component {
    props: PropsType;
    state = {
        currentDay: 0,
    };

    componentDidMount() {
        this.props.actions.getEvents();
    }

    changeDay = (index: number) => {
        this.setState({ currentDay: index });
    };

    renderEvent = (event: Object) => {
        const backgroundColor = eventColor.get(event.type);

        return (
            <div className="event" style={{ backgroundColor }} key={event.id}>
                <div className="eventTime">
                    {event.end && <span className="text">Das</span>}
                    <span className="time">
                        {moment(event.start).format("HH:mm")}
                    </span>
                    {event.end && (
                        <>
                            <span className="text">às</span>
                            <span className="time">
                                {moment(event.end).format("HH:mm")}
                            </span>
                        </>
                    )}
                </div>
                <div className="eventBody">
                    <h1 className="title">
                        {event.type} - {event.title}
                    </h1>
                    <p className="subtitle has-text-white">
                        {event.description}
                    </p>
                    {event.location && (
                        <h2 className="subtitle has-text-white">
                            {event.type === "Workshop" ? (
                                <>
                                    Inscreve-te{" "}
                                    <a
                                        className="link-highlighted is-paddingless"
                                        href="https://docs.google.com/forms/d/e/1FAIpQLSfqIuzuip1brgqxMHA35jBQNz1aixf5GqubkWd4IjL-OrE85Q/viewform"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        aqui
                                    </a>
                                    !
                                </>
                            ) : (
                                `Local: ${event.location}`
                            )}
                        </h2>
                    )}
                </div>
                {event.imageUrl && (
                    <div className="eventFigure">
                        <figure className="image is-128x128">
                            <img src={event.imageUrl} alt="" />
                        </figure>
                    </div>
                )}
            </div>
        );
    };

    render(): ReactElement {
        return (
            <div className="Schedule">
                <div className="tabs is-centered">
                    <ul className="tabs-list">
                        <li
                            className={
                                this.state.currentDay === 0 ? "is-active" : ""
                            }
                        >
                            <a onClick={() => this.changeDay(0)}>Dia 14</a>
                        </li>
                        <li
                            className={
                                this.state.currentDay === 1 ? "is-active" : ""
                            }
                        >
                            <a onClick={() => this.changeDay(1)}>Dia 15</a>
                        </li>
                        <li
                            className={
                                this.state.currentDay === 2 ? "is-active" : ""
                            }
                        >
                            <a onClick={() => this.changeDay(2)}>Dia 16</a>
                        </li>
                        <li
                            className={
                                this.state.currentDay === 3 ? "is-active" : ""
                            }
                        >
                            <a onClick={() => this.changeDay(3)}>Dia 17</a>
                        </li>
                        <li
                            className={
                                this.state.currentDay === 4 ? "is-active" : ""
                            }
                        >
                            <a onClick={() => this.changeDay(4)}>Dia 18</a>
                        </li>
                    </ul>
                </div>
                <section className="container event-container">
                    {this.props.events
                        .filter(
                            event =>
                                event.get("start").getDate() ===
                                    days[this.state.currentDay].getDate() &&
                                eventColor.get(event.type)
                        )
                        .sort((eventA, eventB) => {
                            return (
                                eventA.get("start").getTime() -
                                eventB.get("start").getTime()
                            );
                        })
                        .map(event => this.renderEvent(event))
                        .valueSeq()}
                </section>
                {/* empresas presentes nesse dia do evento  */}
                {companies.get(days[this.state.currentDay]).length > 0 && (
                    <section className="hero is-large">
                        <div className="container box">
                            <div className="box-title-container">
                                <p>
                                    {this.state.currentDay === days.length - 1
                                        ? "Núcleos presentes"
                                        : "Empresas presentes"}
                                    :
                                </p>
                            </div>
                            {_.map(
                                companies.get(days[this.state.currentDay]),
                                (item, index) => {
                                    return index % 3 === 0
                                        ? companies
                                              .get(days[this.state.currentDay])
                                              .slice(index, index + 3)
                                        : null;
                                }
                            )
                                .filter(item => item)
                                .map((row: Array<Object>) => (
                                    <div className="columns">
                                        {row.map((logo: Object) => (
                                            <div className="column company-icon-column">
                                                <figure className="image company-icon">
                                                    <img
                                                        className="company-icon"
                                                        src={logo}
                                                        alt=""
                                                    />
                                                </figure>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                        </div>
                    </section>
                )}
            </div>
        );
    }
}

/* Codigo para por a página em manutenção
<div className="Schedule">
                <div className="hero is-halfheight under-construction">
                    <div className="hero-body">
                        <div className="container has-text-centered ">
                            <p className="title is-2 is-spaced has-text-white">
                                Estamos a programar o calendário
                            </p>
                            <p className="subtitle has-text-white">
                                A pensar em ti e com o intuito de
                                melhorar ano após ano, estamos a agendar
                                as melhores palestras, workshops e
                                programas para ti e para o teu futuro.
                                Aguarda, que estarão para breve novas
                                informações. Qualquer dúvida não hesites em contactar-nos através do nosso email <i className="has-text-info">geral@set-tagus.tecnico.ulisboa.pt</i> ou encontra-nos no<Link to="/contactos" className="has-text-info"> campus Taguspark</Link>.
                            </p>
                        </div>
                    </div>
                </div>
</div> */

const mapStateToProps = (state: State) => ({
    events: state.events.entities,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators(
        {
            getEvents,
        },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
