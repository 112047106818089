/**
 * @flow
 */
import React, { Component, createRef } from "react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";

//  Images
import logoSET from "../resources/logoset.png";
import empresas from "../resources/empresas.png";
import oradores from "../resources/oradores.png";
import premios from "../resources/premios.png";
import workshops from "../resources/workshops.png";
import GearWheels from "../components/GearWheels";

gsap.registerPlugin(ScrollTrigger);

class Home extends Component {
  textRefs = createRef(null);
  logoRef = createRef(null);
  infoRef = createRef(null);
  editionRef = createRef(null);
  infoTitleRef = createRef(null);
  iconsRef = createRef(null);

  constructor(props) {
    super(props);
    this.textRefs.current = [];
    this.infoRef.current = [];
    this.infoTitleRef.current = [];
    this.iconsRef.current = [];
  }

  componentDidMount() {
    gsap.fromTo(
      this.logoRef.current,
      { scale: 0, alpha: 0, ease: "none" },
      { scale: 1, alpha: 1, duration: 0.4 }
    );

    this.textRefs.current.forEach((t) => {
      gsap.fromTo(
        t,
        { scale: 2, alpha: 0, ease: "none" },
        { scale: 1, alpha: 1, duration: 0.5 }
      );
    });

    this.infoTitleRef.current.forEach((r) => {
      gsap.fromTo(
        r,
        {
          x: 100,
          alpha: 0,
        },
        {
          x: 0,
          alpha: 1,
          duration: 0.5,
          scrollTrigger: {
            trigger: r,
            start: "top center+=100",
            end: "bottom top",
          },
        }
      );
    });

    this.infoRef.current.forEach((r) => {
      gsap.fromTo(
        r,
        {
          y: 50,
          alpha: 0,
        },
        {
          y: 0,
          alpha: 1,
          duration: 0.5,
          scrollTrigger: {
            trigger: r,
            start: "top center",
            end: "bottom top",
          },
        }
      );
    });

    this.iconsRef.current.forEach((r) => {
      gsap.fromTo(
        r,
        {
          y: 50,
          alpha: 0,
        },
        {
          y: 0,
          alpha: 1,
          duration: 0.5,
          scrollTrigger: {
            trigger: r,
            start: "top center+=200",
            end: "bottom top",
          },
        }
      );
    });

    gsap.fromTo(
      this.editionRef.current,
      { y: 100, alpha: 0 },
      {
        y: 0,
        alpha: 1,
        duration: 0.5,
        scrollTrigger: {
          trigger: this.editionRef.current,
          start: "top center+=200",
          end: "bottom top",
        },
      }
    );
  }

  setRef = (ref) => {
    if (ref && !this.textRefs.current.includes(ref)) {
      this.textRefs.current.push(ref);
    }
  };

  addToInfoRef = (el) => {
    if (el && !this.infoRef.current.includes(el)) {
      this.infoRef.current.push(el);
    }
  };

  addToInfoTitleRef = (el) => {
    if (el && !this.infoTitleRef.current.includes(el)) {
      this.infoTitleRef.current.push(el);
    }
  };

  addToIconsRef = (el) => {
    if (el && !this.iconsRef.current.includes(el)) {
      this.iconsRef.current.push(el);
    }
  };

  render() {
    return (
      <div className="Home">
        <section className="hero is-small banner">
          <div className="hero-head" />
          <div className="hero-body">
            <div className="container">
              <div className="columns ">
                <div
                  className="column field"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <div className="has-text-centered" ref={this.setRef}>
                    <h1 className="title top">XX Edição</h1>
                  </div>
                  <div className="has-text-centered" ref={this.logoRef}>
                    <h1 className="title">WEBSITE EM MANUTENÇÃO</h1>
                    <GearWheels />
                  </div>

                  {/* <div className="has-text-centered" ref={this.logoRef}>
                    <img
                      className="is-flex-mobile logo"
                      src={logoSET}
                      alt="SET logo"
                    />
                  </div> */}
                  <div className="has-text-centered" ref={this.setRef}>
                    <h1 className="title">13 a 16 de Março de 2023</h1>
                  </div>
                  <div className="has-text-centered" ref={this.setRef}>
                    <h1 className="title">
                      <br />
                      Esperamos por ti!
                      <br />
                      <br />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about-section" className="hero is-large">
          <div className="container about-box">
            <div className="columns">
              <div className="column about-column">
                <h1 className="title about-title" ref={this.addToInfoTitleRef}>
                  &gt; Sobre a SET
                </h1>
                <p className="about-text" ref={this.addToInfoRef}>
                  A&nbsp;
                  <span className="highlighted-text">
                    SET - Semana Empresarial e Tecnológica
                  </span>
                  &nbsp;do Instituto Superior Técnico – Taguspark é um evento
                  organizado por alunos de todos os cursos do campus, cujo
                  objetivo é aproximar os mundos académico e empresarial.
                  <br />
                  Este evento enquadra-se na iniciativa IST Career Weeks, onde
                  se pretende, por um lado, ajudar os alunos a conhecer melhor
                  as oportunidades para o seu futuro profissional, e por outro,
                  ajudar as empresas a divulgar as suas atividades e
                  necessidades.
                  <br />
                  Todos os anos estão presentes empresas e oradores, que
                  partilham as suas experiências e know-how.
                </p>
                <div
                  className="about-icon-box is-hidden-mobile"
                  ref={this.addToIconsRef}
                >
                  <figure className="image is-56x56 about-icon">
                    <img src={empresas} alt="" />
                  </figure>
                  <figure className="image is-56x56 about-icon">
                    <img src={oradores} alt="" />
                  </figure>
                  <figure className="image is-56x56 about-icon">
                    <img src={premios} alt="" />
                  </figure>
                  <figure className="image is-56x56 about-icon">
                    <img src={workshops} alt="" />
                  </figure>
                </div>
              </div>
              <div className="column about-column">
                <h1 className="title about-title" ref={this.addToInfoTitleRef}>
                  &gt; Estabelecer contacto
                </h1>
                <p className="about-text" ref={this.addToInfoRef}>
                  A presença de empresas no evento é considerada uma mais valia
                  para todos. Ao estabelecer contacto com as empresas, os alunos
                  podem descobrir as diversas oportunidades que lhes poderão ser
                  oferecidas quando entrarem no mercado de trabalho.
                  <br />
                  Um painel de oradores prestigiados nas áreas das tecnologias e
                  gestão partilham as suas experiências profissionais com os
                  alunos, incentivando-os a perseguirem os seus objetivos.
                </p>
                <div
                  className="about-icon-box is-hidden-tablet"
                  ref={this.addToIconsRef}
                >
                  <figure className="image is-56x56 about-icon">
                    <img src={empresas} alt="" />
                  </figure>
                  <figure className="image is-56x56 about-icon">
                    <img src={oradores} alt="" />
                  </figure>
                  <figure className="image is-56x56 about-icon">
                    <img src={premios} alt="" />
                  </figure>
                  <figure className="image is-56x56 about-icon">
                    <img src={workshops} alt="" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
                    EDIÇÕES PASSADAS 

                    -   Para adicionar o website da edição do ano anterior, deixei um ficheiro
                        na drive com todos os passos. Qualquer dúvida franciscomrs53@gmail.com .
                
                */}
        <section
          id="about-section"
          className="hero is-large"
          ref={this.editionRef}
        >
          <div className="container about-box">
            <div className="box-title-container">
              <p>Edições Passadas</p>
            </div>
            <div className="columns">
              <div className="column set-link-2018 has-text-centered has-text-left-tablet">
                <h1 className="title about-title">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://set.tecnico.ulisboa.pt/set2018/"
                  >
                    <span className="link-highlighted link-title">
                      &bull;2018
                    </span>
                  </a>
                </h1>
              </div>
              <div className="column set-link-2019 has-text-centered">
                <h1 className="title about-title">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://set.tecnico.ulisboa.pt/set2019/"
                  >
                    <span className="link-highlighted link-title">
                      &bull;2019
                    </span>
                  </a>
                </h1>
              </div>
              <div className="column set-link-2020 has-text-centered">
                <h1 className="title about-title">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://set.tecnico.ulisboa.pt/set2020/"
                  >
                    <span className="link-highlighted link-title">
                      &bull;2020
                    </span>
                  </a>
                </h1>
              </div>
              <div className="column set-link-2021 has-text-centered has-text-right-tablet">
                <h1 className="title about-title">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://set.tecnico.ulisboa.pt/set2021/"
                  >
                    <span className="link-highlighted link-title">
                      &bull;2021
                    </span>
                  </a>
                </h1>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
