/**
 * @flow
 */
import { Map, Record } from "immutable";

export class Curriculum extends Record({
    id: undefined,
    firstName: "",
    lastName: "",
    email: "",
    course: "",
    description: "",
    linkedIn: "",
    speedinterview: "",
    curriculum: "",
    downloadUrl: "",
    fileType: "",
}) {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    course: string;
    description: string;
    linkedIn: string;
    speedinterview: string;
    curriculum: string;
    downloadUrl: string;
    fileType: string;
}

export type CurriculumMapType = Map<string, Curriculum>;

class State extends Record({
    entities: Map(),
    submitting: false,
    submissionError: null,
    submissionSuccessful: null,
}) {
    entities: CurriculumMapType;
    submitting: boolean;
    submissionError: string;
    submissionSuccessful: string;
}

export default State;
