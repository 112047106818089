/**
 * @flow
 */
import React from 'react';
import {
    IoEmail,
    IoLocation,
    IoIosTelephone,
} from 'react-icons/lib/io';
import Map from '../components/Map';

const iconSize = 25;

const Contacts = () => (
    <div className="Contacts">
        <section className="hero is-info">
            <div className="hero-body">
                <div className="container">
                    <h1 className="title">
                        CONTACTOS
                    </h1>
                    <div className="columns">
                        <div className="column">
                            <div className="icon">
                                <IoLocation size={iconSize}/>
                            </div>
                            <h1 className="subtitle">
                                LOCALIZAÇÃO
                            </h1>
                            <span>
                                <strong>Instituto Superior Técnico</strong><br/>
                                Campus Taguspark<br/>
                                Av. Prof. Doutor Cavaco Silva,<br/>
                                2744-016 Porto Salvo<br/>
                            </span>
                        </div>
                        <div className="column">
                            <div className="icon">
                                <IoIosTelephone size={iconSize}/>
                            </div>
                            <h1 className="subtitle">
                                CONTACTOS
                            </h1>
                            <span>
                                <div style={{ display: 'flex' }}>
                                    <IoEmail size={iconSize}/>
                                    <div style={{ paddingLeft: 5 }}>
                                        geral@set-tagus.tecnico.ulisboa.pt
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <IoEmail size={iconSize}/>
                                    <div style={{ paddingLeft: 5 }}>
                                        publicrelations@set-tagus.tecnico.ulisboa.pt
                                    </div>
                                </div>
                                <br/>
                                <div style={{ display: 'flex' }}>
                                    <IoIosTelephone size={iconSize}/>
                                    <div style={{ paddingLeft: 5 }}>
                                        +351 214 233 759 (5179)
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="map-container">
            <Map />
        </div>
        <div className="faq-container">
            <h1 className="title">
                FREQUENTLY ASKED QUESTIONS
            </h1>
            <ol>
                <li>O que é a SET?</li>
                <p>A SET, Semana Empresarial e Tecnológica, é o maior evento do Técnico
                    Taguspark. Possibilita a ligação entre estudantes e empresas oferecendo,
                    para além de workshops e palestras, a oportunidade única de falar
                    diretamente com as mais prestigiadas empresas das áreas de Tecnologia
                    e Gestão.
                </p>
                <li>Como posso participar na SET?</li>
                <p>A SET está aberta a toda a comunidade estudantil. Para participar na
                    XVIII Edição só tens que te deslocar até ao Instituto Superior Técnico
                    – campus Taguspark nos dias 14 a 18 de Março de 2022 e escolher as
                    palestras e atividades que mais te interessem! Podes consultar o horário
                    de todos os eventos <a href="https://www.facebook.com/SET.Tecnico">aqui</a>.
                </p>
                <li>Como faço a inscrição para um dos workshops?</li>
                <p>Na nossa página de Facebook será publicado, à data do evento,
                    um formulário para a inscrição prévia em cada workshop.
                </p>
                <li>Que oradores/empresas vão estar presentes no evento?</li>
                <p>Na SET, as empresas poderão dar-se a conhecer através de stands,
                    que permitem um contacto direto com os alunos e/ou mediante palestras/workshops
                    que possibilita uma abordagem mais dinâmica. Consulta o nosso cartaz <a href="https://www.facebook.com/SET.Tecnico">aqui</a>.
                </p>
                <li>Represento uma empresa e pretendo estabelecer uma parceria com o evento,
                    a quem me devo dirigir?
                </li>
                <p>Por favor entre em contacto por email para <span className="bold">coordenação.set@gmail.com</span> ou por
                    telefone para <span className="bold">+351&nbsp;214&nbsp;233&nbsp;579&nbsp;(5179)</span>.
                </p>
                <li>Como posso fazer parte da equipa da próxima SET?</li>
                <p>Junta-te a nós! Basta estar atento à nossa página de Facebook, as
                    inscrições para 2022 irão abrir e poderás candidatar-te, ou envia-nos
                    um email para <span className="bold">geral@set-tagus.tecnico.ulisboa.pt</span>.
                </p>
            </ol>
        </div>
    </div>
);

export default Contacts;
