/**
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkin } from '../actions/checkin';

type PropsType = {
    actions:{
        checkin:(name:string, istid:string, telephone:string, email:string, code:string) => void,
    },
    isSubmitting:boolean,
    submissionSuccessful:string,
    error:string,
};

class CheckIn extends React.Component {
    props:PropsType;
    state:{
        name:string,
        istid:string,
        telephone:string,
        email:string,
        code:string,
        formErrors: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            istid: '',
            telephone: '',
            email: '',
            code: '',
            formErrors: {},
        };
    }

    handleNameChange = (event) => {
        this.setState({ name: event.target.value });
    };

    handleIstidChange = (event) => {
        this.setState({ istid: event.target.value });
    };

    handleTelephoneChange = (event) => {
        this.setState({ telephone: event.target.value });
    };

    handleEmailChange = (event) => {
        this.setState({ email: event.target.value });
    };

    handleCodeChange = (event) => {
        this.setState({ code: event.target.value });
    };


    validateForm = () => {
        const formErrors = {};

        if(!this.state.name || this.state.name.length === 0) {
            formErrors.name = 'Campo obrigatório';
        }

        if(!this.state.istid.match(/^1[0-9\-+]{5}$/) && !this.state.istid.match(/^[0-9\-+]{5}$/)) {
            formErrors.istid = 'ID inválido ou vazio';
        }

        if(!this.state.telephone.match(/^9[0-9\-+]{8}$/)) {
            formErrors.telephone = 'Número inválido ou vazio';
        }

        if (!this.state.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            formErrors.email = 'Endereço inválido ou vazio';
        }

        if(!this.state.code.match(/^[0-9A-Fa-f]{8}$/)) {
            formErrors.code = 'Código inválido ou vazio';
        }

        this.setState({ formErrors });
        return Object.keys(formErrors).length === 0;
    }

    handleFormSubmit = () => {
        if (this.validateForm()) {
            this.props.actions.checkin(this.state.name, this.state.istid, this.state.telephone, this.state.email, this.state.code.toUpperCase());
        }
        this.setState({
            name: '',
            istid: '',
            telephone: '',
            email: '',
            code: '',
        });
    };

    render():ReactElement {
        return (
            <div className="CurriculumLogin">
                <section id="about-section">
                    <div className="container about-box" style={{ padding: 50, paddingBottom: 25 }}>
                        <div className="box-title-container">
                            <p>Check-In</p>
                        </div>
                        <div className="columns" style={{ flexDirection: 'column', flexGrow: 0 }}>
                            <div className="column field">
                                <label className="label">Primeiro e Último Nome</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        value={this.state.name}
                                        onChange={this.handleNameChange}
                                        type="text"
                                        placeholder="Primeiro e Último Nome"
                                    />
                                </div>
                                {this.state.formErrors.name &&
                                <p className="help is-danger">{this.state.formErrors.name}</p>}
                            </div>
                            <div className="column field">
                                <label className="label">IST ID (Apenas os algarismos)</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        value={this.state.istid}
                                        onChange={this.handleIstidChange}
                                        type="text"
                                        placeholder="1XXXXX ou XXXXX"
                                    />
                                </div>
                                {this.state.formErrors.istid &&
                                <p className="help is-danger">{this.state.formErrors.istid}</p>}
                            </div>
                            <div className="column field">
                                <label className="label">Nº Telemóvel</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        value={this.state.telephone}
                                        onChange={this.handleTelephoneChange}
                                        type="text"
                                        placeholder="9XXXXXXX"
                                    />
                                </div>
                                {this.state.formErrors.telephone &&
                                <p className="help is-danger">{this.state.formErrors.telephone}</p>}
                            </div>
                            <div className="column field">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className="input" value={this.state.email} onChange={this.handleEmailChange}
                                           type="email" placeholder="Email"/>
                                </div>
                                {this.state.formErrors.email &&
                                <p className="help is-danger">{this.state.formErrors.email}</p>}
                            </div>
                            <div className="column field">
                                <label className="label">Código Único</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        value={this.state.code}
                                        onChange={this.handleCodeChange}
                                        type="text"
                                        placeholder="XXXXXXXX"
                                    />
                                </div>
                                {this.state.formErrors.code &&
                                <p className="help is-danger">{this.state.formErrors.code}</p>}
                            </div>
                        </div>
                        <div className="columns" style={{ marginBottom: 10, justifyContent: 'flex-end' }}>
                            <div className="column">
                                {(this.props.submissionSuccessful != null &&
                                    this.props.submissionSuccessful.length > 0) &&
                                <div className="notification is-primary">
                                    {this.props.submissionSuccessful}
                                </div>}
                                {(this.props.error != null &&
                                    this.props.error.length > 0) &&
                                <div className="notification is-danger">
                                    Erro: {this.props.error}
                                </div>}
                            </div>
                            <div className="column" style={{ flexGrow: 0 }}>
                                <a
                                    className={`button${this.props.isSubmitting ? ' is-loading' : ''}`}
                                    onClick={this.handleFormSubmit}
                                    style={{ marginTop: 10, color: '#32A9D6' }}
                                >
                                    Submeter
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state:State) => ({
    isSubmitting: state.checkin.isSubmitting,
    submissionSuccessful: state.checkin.submissionSuccessful,
    error: state.checkin.error,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
    actions: bindActionCreators({
        checkin,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
