/**
 * @flow
 */
import { Dispatch } from 'redux';

export const actionTypes = {
    SET_SUBMITTING: 'CHECKIN/SET_SUBMITTING',
    SET_SUBMISSION_ERROR: 'CHECKIN/SET_SUBMISSION_ERROR',
    SET_SUBMISSION_SUCCESSFUL: 'CHECKIN/SET_SUBMISSION_SUCCESSFUL',
};

export const setIsSubmitting = (isSubmitting:boolean) => ({
    type: actionTypes.SET_SUBMITTING,
    payload: isSubmitting,
});

export const setSubmissionSuccessful = (submissionSuccessful:string) => ({
    type: actionTypes.SET_SUBMISSION_SUCCESSFUL,
    payload: submissionSuccessful,
});

export const setError = (error:string) => ({
    type: actionTypes.SET_SUBMISSION_ERROR,
    payload: error,
});

const checkinUrl = 'https://set.tecnico.ulisboa.pt/api/checkin_companies.php';

export const checkin = (password:string, name:string, istid:string, telephone:string, email:string, entries:string) =>
    async (dispatch:Dispatch) => {
        try {
            await dispatch(setIsSubmitting(true));

            const body = {
                details: {
                    password,
                    name,
                    istid,
                    telephone,
                    email,
                    entries,
                },
            };

            const response = await fetch(checkinUrl, {
                method: 'POST',
                body: JSON.stringify(body),
            });

            switch(response.status) {
                case(200):
                    await dispatch(setSubmissionSuccessful('Check-in realizado com sucesso!'));
                    break;
                case (401):
                    await dispatch(setError('Utilizador não autorizado'));
                    break;
                case (443):
                    await dispatch(setError('Não foi possível dar commit, por favor contacte a organização'));
                    break;
                default:
                    await dispatch(setError('Erro geral, por favor contacte a organização'));
            }
        } catch (e) {
            await dispatch(setError(e));
        }
        await dispatch(setIsSubmitting(false));
    };
