/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @flow
 */
import React from "react";
import { Link } from "react-router-dom";

import NavbarContactButtons from "./NavbarContactButtons";

// Logos
import logoIST from "../resources/logoist2.png";
import logoISTSmall from "../resources/logoist-small.png";
import logoSET from "../resources/logoSET256x128.png";

type PropsType = {
  isActive: boolean,
  onClickBurger: () => void,
  canClick: boolean,
};

const Navbar = (props: PropsType) => (
  <div
    className="navbar is-contrast-blue"
    role="navigation"
    aria-label="main navigation"
  >
    <div className="navbar-brand">
      <figure>
        <a href="https://tecnico.ulisboa.pt">
          <picture>
            <source media="(max-width: 768px)" srcSet={logoISTSmall} />
            <img className="logo" src={logoIST} alt="IST logo" />
          </picture>
        </a>
      </figure>
      <figure className="is-pulled-right">
        <Link to="/">
          <img className="logo" src={logoSET} alt="IST logo" />
        </Link>
      </figure>
      <button
        className={`button navbar-burger is-contrast-blue${
          props.isActive ? " is-active" : ""
        }`}
        onClick={props.onClickBurger}
      >
        <span />
        <span />
        <span />
      </button>
    </div>
    <div className={`navbar-menu${props.isActive ? " is-active" : ""} is-dark`}>
      <div className="navbar-end is-contrast-blue">
        <Link to="/" className="navbar-item is-contrast-blue">
          ←Início
        </Link>
        {props.canClick ? (
          <Link to="/programa" className="navbar-item is-contrast-blue">
            Programa
          </Link>
        ) : (
          <Link to="/" className="navbar-item is-contrast-blue not-clickable">
            Programa
          </Link>
        )}
        {props.canClick ? (
          <Link to="/empresas" className="navbar-item is-contrast-blue">
            Empresas
          </Link>
        ) : (
          <Link to="/" className="navbar-item is-contrast-blue not-clickable">
            Empresas
          </Link>
        )}
        {props.canClick ? (
          <Link to="/atividades" className="navbar-item is-contrast-blue">
            Atividades
          </Link>
        ) : (
          <Link to="/" className="navbar-item is-contrast-blue not-clickable">
            Atividades
          </Link>
        )}
        {props.canClick ? (
          <Link to="/estudantes" className="navbar-item is-contrast-blue">
            Currículos
          </Link>
        ) : (
          <Link to="/" className="navbar-item is-contrast-blue not-clickable">
            Currículos
          </Link>
        )}
        {props.canClick ? (
          <Link to="/equipa" className="navbar-item is-contrast-blue">
            Equipa
          </Link>
        ) : (
          <Link to="/" className="navbar-item is-contrast-blue not-clickable">
            Equipa
          </Link>
        )}
        {props.canClick ? (
          <Link to="/premios" className="navbar-item is-contrast-blue">
            Prémios
          </Link>
        ) : (
          <Link to="/" className="navbar-item is-contrast-blue not-clickable">
            Prémios
          </Link>
        )}
        {/*<Link to="/estudantes" className="navbar-item is-contrast-blue">
                    Submissão de Currículos
                </Link>*/}
        <Link to="/contactos" className="navbar-item is-contrast-blue">
          Contactos
        </Link>

        <div className="is-hidden-tablet">
          <hr style={{ margin: 0 }} />
          <NavbarContactButtons />
        </div>
      </div>
    </div>
  </div>
);

export default Navbar;

/* PAGINA CURRICULOS
<Link to="/estudantes" className="navbar-item is-contrast-blue">
Currículos
</Link>
*/
