/**
 * @flow
 */
import React, { Component, createRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

import TeamMemberPortrait from "../components/TeamMemberPortrait";

// Coordenacao
import carolinaFerreira from "../resources/team/coordenacao/Carolina Ferreira.webp";
import helenaTeixeira from "../resources/team/coordenacao/Helena Teixeira.webp";
import ricardoSimoes from "../resources/team/coordenacao/Ricardo Simões.webp";

// Marketing
import madalenaBarbeitos from "../resources/team/marketing/Madalena Barbeitos.webp";
import franciscaMartins from "../resources/team/marketing/Francisca Martins.webp";
import franciscoGoncalves from "../resources/team/marketing/Francisco Gonçalves.webp";
import guilhermeBrazSilva from "../resources/team/marketing/Guilherme Silva.webp";
import joaoDomingos from "../resources/team/marketing/João Domingos.webp";
import carmoCordeiro from "../resources/team/marketing/Carmo Cordeiro.webp";
import mariaCabeleiraJesus from "../resources/team/marketing/Maria Cabeleira Jesus.webp";
import matildePinheiro from "../resources/team/marketing/Matilde Pinheiro.webp";
import ricardoCarvalho from "../resources/team/marketing/Ricardo Carvalho.webp";

// Design
import luisDias from "../resources/team/design/Luis Dias.webp";

// Relações Externas
import mariaTeresaMadeira from "../resources/team/RE/Maria Teresa Madeira.webp";
import alexandreBento from "../resources/team/RE/Alexandre Bento.webp";
import anaOliveira from "../resources/team/RE/Ana Oliveira.webp";
import filipaBraz from "../resources/team/RE/Filipa Braz.webp";
import franciscoPedro from "../resources/team/RE/Francisco Pedro.webp";
import isabelPires from "../resources/team/RE/Isabel Pires.webp";
import joseCosta from "../resources/team/RE/José Costa.webp";
import manuelCandeias from "../resources/team/RE/Manuel Candeias.webp";
import martimAbreu from "../resources/team/RE/Martim Abreu.webp";
import pedroDeOliveiraTeixeira from "../resources/team/RE/Pedro Teixeira.webp";
import ricardoPinto from "../resources/team/RE/Ricardo Pinto.webp";
import tiagoDelgado from "../resources/team/RE/Tiago Delgado.webp";

// Logistica
import joaoLobato from "../resources/team/logistica/João Lobato.webp";
import beatrizAlves from "../resources/team/logistica/Beatriz Alves.webp";
import bernardoOrmonde from "../resources/team/logistica/Bernardo Ormonde.webp";
import bernardoPinto from "../resources/team/logistica/Bernardo Pinto.webp";
import constancaFlorindo from "../resources/team/logistica/Constança Florindo.webp";
import contancaPrimor from "../resources/team/logistica/Constança Primor.webp";
import fabioSoares from "../resources/team/logistica/Fábio Soares.webp";
import inesCardeira from "../resources/team/logistica/Inês Cardeira.webp";
import joaoBayeux from "../resources/team/logistica/João Bayeux.webp";
import joaoBugalho from "../resources/team/logistica/João Bugalho.webp";
import joseAfonsoGarcia from "../resources/team/logistica/José Afonso Garcia.webp";
import leonorMira from "../resources/team/logistica/Leonor Mira.webp";
import liaNeves from "../resources/team/logistica/Lia Neves.webp";
import mariaCarolinaPessoa from "../resources/team/logistica/Maria Carolina Pessoa.webp";
import mariaMoura from "../resources/team/logistica/Maria Moura.webp";
import marianaFarinha from "../resources/team/logistica/Mariana Farinha.webp";
import martaAugusto from "../resources/team/logistica/Marta Augusto.webp";

// Suporte Informático
import franciscoSilva from "../resources/team/suporte informatico/Francisco Silva.webp";
import samuelBarata from "../resources/team/suporte informatico/Samuel Barata.webp";

//  No photo
// import unknown from "../resources/team/unknown.jpg";

type TeamMemberType = {
    name: string,
    portrait: Object,
    group: string,
    role: string,
    url: string,
    color: string,
};

const coordenacao = [
    {
        name: "Carolina Ferreira",
        portrait: carolinaFerreira,
        group: "LAGE",
        color: "orange",
        role: "Co-coordenadora",
    },
    {
        name: "Ricardo Simões",
        portrait: ricardoSimoes,
        group: "LAGE",
        color: "orange",
        role: "Co-coordenador",
        url: "https://www.linkedin.com/in/ricardo-simoes00/",
    },
    {
        name: "Helena Teixeira",
        portrait: helenaTeixeira,
        group: "LAGE",
        color: "orange",
        role: "Co-coordenadora",
        url: "https://www.linkedin.com/in/helenateixeira-info/",
    },
];

const relacoesExternas = [
    {
        name: "Maria Teresa Madeira",
        portrait: mariaTeresaMadeira,
        role: "Coordenadora",
    },
    {
        name: "Alexandre Bento",
        portrait: alexandreBento,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/alexandre-esteves-bento-351946216",
    },
    {
        name: "Ana Oliveira",
        portrait: anaOliveira,
        role: "Colaboradora",
        url: "https://www.linkedin.com/in/anaclloliveira",
    },
    {
        name: "Filipa Braz",
        portrait: filipaBraz,
        role: "Colaboradora",
        url: "https://www.linkedin.com/in/filipa-braz/",
    },
    {
        name: "Francisco Pedro",
        portrait: franciscoPedro,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/francisco-pedro-a27a99201/",
    },
    {
        name: "Isabel Pires",
        portrait: isabelPires,
        role: "Colaboradora",
    },
    {
        name: "José Costa",
        portrait: joseCosta,
        role: "Colaborador",
    },
    {
        name: "Manuel Candeias",
        portrait: manuelCandeias,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/manuel-candeias-b1a017224",
    },
    {
        name: "Martim Abreu",
        portrait: martimAbreu,
        group: "NEETI",
        color: "black",
        role: "Colaborador",
        url: "https://www.linkedin.com/in/martim-abreu-4b8a29225",
    },
    {
        name: "Pedro de Oliveira Teixeira",
        portrait: pedroDeOliveiraTeixeira,
        group: "NEEGI",
        color: "black",
        role: "Colaborador",
        url: "https://www.linkedin.com/in/pedro-de-oliveira-teixeira-656ba5223/",
    },
    {
        name: "Ricardo Pinto",
        portrait: ricardoPinto,
        group: "NEEGI",
        color: "black",
        role: "Colaborador",
    },
    {
        name: "Tiago Delgado",
        portrait: tiagoDelgado,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/tiago-delgado-775535223",
    },
];

const marketing = [
    {
        name: "Madalena Barbeitos",
        portrait: madalenaBarbeitos,
        role: "Coordenadora",
        url: "https://www.linkedin.com/in/maria-madalena-barbeitos-a17b28116/",
    },
    {
        name: "Francisca Martins",
        portrait: franciscaMartins,
        group: "NEEGI",
        color: "black",
        role: "Colaboradora",
        url: "https://www.linkedin.com/in/francisca-ferreira-martins-5254a1221",
    },
    {
        name: "Francisco  Gonçalves ",
        portrait: franciscoGoncalves,
        group: "NEEGI",
        color: "black",
        role: "Colaborador",
        url: "https://www.linkedin.com/in/francisco-gon%C3%A7alves-0a59a2224/",
    },
    {
        name: "Guilherme Braz Silva",
        portrait: guilhermeBrazSilva,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/guilherme-braz-silva-2a7839204/",
    },
    {
        name: "João Domingos",
        portrait: joaoDomingos,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/jo%C3%A3o-domingos-a199161a3/",
    },
    {
        name: "Carmo Cordeiro",
        portrait: carmoCordeiro,
        role: "Colaboradora",
    },
    {
        name: "Maria Cabeleira Jesus",
        portrait: mariaCabeleiraJesus,
        role: "Colaboradora",
        url: "https://www.linkedin.com/in/maria-jesus-2ab882222",
    },
    {
        name: "Matilde Pinheiro",
        portrait: matildePinheiro,
        group: "NEETI",
        color: "black",
        role: "Colaboradora",
        url: "https://www.linkedin.com/in/matildepinheiro/",
    },
    {
        name: "Ricardo Carvalho",
        portrait: ricardoCarvalho,
        role: "Colaborador",
    },
    {
        name: "Luis Dias",
        portrait: luisDias,
        role: "Colaborador",
    },
];

const logistica = [
    {
        name: "João Lobato",
        portrait: joaoLobato,
        role: "Coordenador",
        url: "https://www.linkedin.com/in/jo%C3%A3o-lobato-57b9401a3/",
    },
    {
        name: "Beatriz Alves",
        portrait: beatrizAlves,
        group: "NEIIST",
        color: "blue",
        role: "Colaboradora",
    },
    {
        name: "Bernardo Ormonde",
        portrait: bernardoOrmonde,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/bernardo-ormonde-3973171b8",
    },
    {
        name: "Bernardo Pinto",
        portrait: bernardoPinto,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/bernardo-pinto/",
    },
    {
        name: "Constança Florindo",
        portrait: constancaFlorindo,
        role: "Colaboradora",
    },
    {
        name: "Constança Primor",
        portrait: contancaPrimor,
        role: "Colaboradora",
    },
    {
        name: "Fábio Soares",
        portrait: fabioSoares,
        role: "Colaborador",
    },
    {
        name: "Inês Cardeira",
        portrait: inesCardeira,
        role: "Colaboradora",
        url: "https://www.linkedin.com/in/inesmcardeira",
    },
    {
        name: "João Bayeux",
        portrait: joaoBayeux,
        role: "Colaborador",
    },
    {
        name: "João Bugalho",
        portrait: joaoBugalho,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/jo%C3%A3o-bugalho-4a7790222",
    },
    {
        name: "José Afonso Garcia",
        portrait: joseAfonsoGarcia,
        group: "NEIIST",
        color: "blue",
        role: "Colaborador",
        url: "https://www.linkedin.com/in/joseafonsogarcia",
    },
    {
        name: "Leonor Mira",
        portrait: leonorMira,
        group: "N3E",
        color: "darkgrey",
        role: "Colaboradora",
    },
    {
        name: "Lia Neves",
        portrait: liaNeves,
        role: "Colaboradora",
    },
    {
        name: "Maria Carolina Pessoa",
        portrait: mariaCarolinaPessoa,
        role: "Colaboradora",
        url: "https://www.linkedin.com/in/mariacarolinapessoa/",
    },
    {
        name: "Maria Moura",
        portrait: mariaMoura,
        role: "Colaboradora",
    },
    {
        name: "Mariana Farinha",
        portrait: marianaFarinha,
        group: "NEEGI",
        color: "black",
        role: "Colaboradora",
    },
    {
        name: "Marta Augusto",
        portrait: martaAugusto,
        role: "Colaboradora",
        url: "https://www.linkedin.com/in/martaclaugusto",
    },
];

const suporteInformatico = [
    {
        name: "Francisco Silva",
        portrait: franciscoSilva,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/francisco-ribeiro-e-silva-144b511a9/",
    },
    {
        name: "Samuel Barata",
        portrait: samuelBarata,
        role: "Colaborador",
        url: "https://www.linkedin.com/in/samuel-barata/",
        group: "NEETI",
        color: "black",
    },
];

gsap.registerPlugin(ScrollTrigger);

class Team extends Component {
    coordenationRef = createRef(null);
    logisticRef = createRef(null);
    marketingRef = createRef(null);
    relationsRef = createRef(null);
    itRef = createRef(null);
    titlesRef = createRef(null);

    constructor(props) {
        super(props);
        this.coordenationRef.current = [];
        this.logisticRef.current = [];
        this.marketingRef.current = [];
        this.relationsRef.current = [];
        this.itRef.current = [];
        this.titlesRef.current = [];
    }

    addToCoordenationRef = el => {
        if (el && !this.coordenationRef.current.includes(el)) {
            this.coordenationRef.current.push(el);
        }
    };

    addToLogisticRef = el => {
        if (el && !this.logisticRef.current.includes(el)) {
            this.logisticRef.current.push(el);
        }
    };

    addToMarketingRef = el => {
        if (el && !this.marketingRef.current.includes(el)) {
            this.marketingRef.current.push(el);
        }
    };

    addTorelationsRef = el => {
        if (el && !this.relationsRef.current.includes(el)) {
            this.relationsRef.current.push(el);
        }
    };

    addToItRef = el => {
        if (el && !this.itRef.current.includes(el)) {
            this.itRef.current.push(el);
        }
    };

    addToTitlesRef = el => {
        if (el && !this.titlesRef.current.includes(el)) {
            this.titlesRef.current.push(el);
        }
    };

    componentDidMount() {
        this.coordenationRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=200",
                        end: "bottom top",
                    },
                }
            );
        });

        this.logisticRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=200",
                        end: "bottom top",
                    },
                }
            );
        });

        this.marketingRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=200",
                        end: "bottom top",
                    },
                }
            );
        });

        this.relationsRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=200",
                        end: "bottom top",
                    },
                }
            );
        });

        this.itRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=200",
                        end: "bottom top",
                    },
                }
            );
        });

        this.titlesRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    x: 100,
                    alpha: 0,
                },
                {
                    x: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=300",
                        end: "bottom top",
                    },
                }
            );
        });
    }

    render(): ReactElement {
        return (
            <div className="Team">
                <section id="coordination-section" className="hero is-large">
                    <div className="container box">
                        <div
                            className="box-title-container"
                            ref={this.addToTitlesRef}
                        >
                            <p>Comité Executivo</p>
                        </div>
                        <div className="columns is-mobile is-multiline is-centered">
                            {coordenacao.map((member: TeamMemberType) => (
                                <div
                                    className="column"
                                    ref={this.addToCoordenationRef}
                                >
                                    <TeamMemberPortrait
                                        name={member.name}
                                        portrait={member.portrait}
                                        url={member.url || ""}
                                        containerClassName="portraitContainer"
                                        titleContainerClassName="portraitTitleContainer"
                                        portraitClassName="portraitFigure"
                                        group={member.group}
                                        color={member.color}
                                        role={member.role}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section id="coordination-section" className="hero is-large">
                    <div className="container box">
                        <div
                            className="box-title-container"
                            ref={this.addToTitlesRef}
                        >
                            <p>Logística</p>
                        </div>
                        <div className="columns is-mobile is-multiline is-centered">
                            {logistica.map((member: TeamMember) => (
                                <div
                                    className="column is-one-third-widescreen"
                                    ref={this.addToLogisticRef}
                                >
                                    <TeamMemberPortrait
                                        name={member.name}
                                        portrait={member.portrait}
                                        url={member.url || ""}
                                        containerClassName="portraitContainer"
                                        titleContainerClassName="portraitTitleContainer"
                                        portraitClassName="portraitFigure"
                                        group={member.group}
                                        color={member.color}
                                        role={member.role}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section id="coordination-section" className="hero is-large">
                    <div className="container box">
                        <div
                            className="box-title-container"
                            ref={this.addToTitlesRef}
                        >
                            <p>Marketing</p>
                        </div>
                        <div className="columns is-mobile is-multiline is-centered">
                            {marketing.map((member: TeamMemberType) => (
                                <div
                                    className="column is-one-third-widescreen"
                                    ref={this.addToMarketingRef}
                                >
                                    <TeamMemberPortrait
                                        name={member.name}
                                        portrait={member.portrait}
                                        url={member.url}
                                        containerClassName="portraitContainer"
                                        titleContainerClassName="portraitTitleContainer"
                                        portraitClassName="portraitFigure"
                                        group={member.group}
                                        color={member.color}
                                        role={member.role}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section id="coordination-section" className="hero is-large">
                    <div className="container box">
                        <div
                            className="box-title-container"
                            ref={this.addToTitlesRef}
                        >
                            <p>Relações Externas</p>
                        </div>
                        <div className="columns is-mobile is-multiline is-centered">
                            {relacoesExternas.map((member: TeamMemberType) => (
                                <div
                                    className="column is-one-third-widescreen"
                                    ref={this.addTorelationsRef}
                                >
                                    <TeamMemberPortrait
                                        name={member.name}
                                        portrait={member.portrait}
                                        url={member.url || ""}
                                        containerClassName="portraitContainer"
                                        titleContainerClassName="portraitTitleContainer"
                                        portraitClassName="portraitFigure"
                                        group={member.group}
                                        color={member.color}
                                        role={member.role}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section id="coordination-section" className="hero is-large">
                    <div className="container box">
                        <div
                            className="box-title-container"
                            ref={this.addToTitlesRef}
                        >
                            <p>Suporte Informático</p>
                        </div>
                        <div className="columns is-mobile is-multiline is-centered">
                            {suporteInformatico.map(
                                (member: TeamMemberType) => (
                                    <div
                                        className="column is-one-third-widescreen"
                                        ref={this.addToItRef}
                                    >
                                        <TeamMemberPortrait
                                            name={member.name}
                                            portrait={member.portrait}
                                            url={member.url || ""}
                                            containerClassName="portraitContainer"
                                            titleContainerClassName="portraitTitleContainer"
                                            portraitClassName="portraitFigure"
                                            group={member.group}
                                            color={member.color}
                                            role={member.role}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Team;
