/**
 * @flow
 */
// Imports
import { Map } from 'immutable';
import type { Action } from 'redux';

import { actionTypes } from '../actions/events';
import State, { Event } from '../types/events';

/**
 * Alert reducer function.
 *
 * @param state Current state.
 * @param action Action to process.
 * @returns {*} Next state.
 */
const eventReducer = (state:?State = new State(), action:Action):State => {
    if (!(state instanceof State)) {
        return new State().mergeDeep(state);
    }

    switch (action.type) {
        case actionTypes.ADD_EVENTS:
            const dataArray =
                Array.isArray(action.payload) ? action.payload : [action.payload];
            return state.mergeIn(
                ['entities'],
                Map(dataArray.map((object:Object):[string, Object] =>
                    [object.id, object instanceof Event ? object : new Event(object)])),
            );

        default:
            return state;
    }
};

export default eventReducer;
