/**
 * @flow
 */
import React from 'react';
import { icon as newIcon } from 'leaflet';
import {
    Map as LeafletMap,
    Marker,
    Popup,
    TileLayer,
} from 'react-leaflet';

const position = [38.73722, -9.30322];

const Map = () => (
    <LeafletMap center={position} zoom={16} scrollWheelZoom={false}>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <Marker
            position={position}
            icon={newIcon({
                iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.2.0/images/marker-icon.png',
                iconAnchor: [12, 41],
                popupAnchor: [0, -41],
            })}
        >
            <Popup>
                <span>
                    <strong>Instituto Superior Técnico</strong><br />
                    <strong>Campus Taguspark</strong><br />
                    Av. Prof. Doutor Cavaco Silva,<br />
                    2744-016 Porto Salvo<br />
                </span>
            </Popup>
        </Marker>
    </LeafletMap>
);

export default Map;
