/**
 * @flow
 */
import { Map, Record } from 'immutable';

export class Event extends Record({
    id: undefined,
    type: undefined,
    description: undefined,
    title: undefined,
    imageUrl: undefined,
    start: undefined,
    end: undefined,
    location: undefined,
}) {
    id:string;
    type:string;
    description:string;
    title:string;
    imageUrl:string;
    start:Date;
    end:Date;
    location:string;
}

export type EventMapType = Map<string, Event>;

class State extends Record({
    entities: Map(),
}) {
    entities:EventMapType;
}

export default State;
