/**
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login } from '../actions/user';

type PropsType = {
    actions:{
        login:(username:string, password:string) => void,
    },
    isSubmitting:boolean,
    error:string,
};

class CurriculumLogin extends React.Component {
    props:PropsType;
    state:{
        username:string,
        password:string,
    };

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
        };
    }

    handleUsernameChange = (event) => {
        this.setState({ username: event.target.value });
    };

    handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    };

    handleFormSubmit = () => {
        this.props.actions.login(this.state.username, this.state.password);
        this.setState({
            username: '',
            password: '',
        });
    };

    render():ReactElement {
        return (
            <div className="CurriculumLogin">
                <section id="about-section">
                    <div className="container about-box" style={{ padding: 50, paddingBottom: 25 }}>
                        <div className="box-title-container">
                            <p>Login</p>
                        </div>
                        <div className="columns" style={{ flexDirection: 'column', flexGrow: 0 }}>
                            <div className="column field">
                                <label className="label">Nome de Utilizador</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        value={this.state.username}
                                        onChange={this.handleUsernameChange}
                                        type="text"
                                        placeholder="Nome de utilizador"
                                    />
                                </div>
                            </div>
                            <div className="column field">
                                <label className="label">Palavra-passe</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        onChange={this.handlePasswordChange}
                                        value={this.state.password}
                                        type="password"
                                        placeholder="Palavra-passe"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="columns" style={{ marginBottom: 10, justifyContent: 'flex-end' }}>
                            <div className="column">
                                {(this.props.error != null &&
                                    this.props.error.length > 0) &&
                                <div className="notification is-danger">
                                    Erro a fazer login, por favor tente novamente!
                                </div>}
                            </div>
                            <div className="column" style={{ flexGrow: 0 }}>
                                <a
                                    className={`button${this.props.isSubmitting ? ' is-loading' : ''}`}
                                    onClick={this.handleFormSubmit}
                                    style={{ marginTop: 10, color: '#32A9D6' }}
                                >
                                    Submeter
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state:State) => ({
    isSubmitting: state.user.isSubmitting,
    error: state.user.error,
});

const mapDispatchToProps = (dispatch:Dispatch) => ({
    actions: bindActionCreators({
        login,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurriculumLogin);
