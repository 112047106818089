/**
 * @flow
 */
import { Record } from "immutable";

class State extends Record({
    token: null,
    error: null,
    isSubmitting: false,
}) {
    token: string;
    error: string;
    isSubmitting: boolean;
}

export default State;
