/**
 * @flow
 */
import React, { useEffect, useRef } from "react";

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Speakers = () => {
    const speedInterviewRef = useRef([]);
    const oneToOneRef = useRef([]);
    const workshopsRef = useRef([]);
    const oradoresRef = useRef([]);
    const titlesRef = useRef([]);

    useEffect(() => {
        speedInterviewRef.current.forEach((r, index) => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    delay: 0.1 + index * 0.15,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=200",
                        end: "bottom top",
                    },
                }
            );
        });

        oneToOneRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=200",
                        end: "bottom top",
                    },
                }
            );
        });

        workshopsRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=200",
                        end: "bottom top",
                    },
                }
            );
        });

        oradoresRef.current.forEach((r, index) => {
            gsap.fromTo(
                r,
                {
                    y: 50,
                    alpha: 0,
                },
                {
                    y: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=200",
                        end: "bottom top",
                    },
                }
            );
        });

        titlesRef.current.forEach(r => {
            gsap.fromTo(
                r,
                {
                    x: 100,
                    alpha: 0,
                },
                {
                    x: 0,
                    alpha: 1,
                    duration: 0.5,
                    scrollTrigger: {
                        trigger: r,
                        start: "top center+=100",
                        end: "bottom top",
                    },
                }
            );
        });
    }, [speedInterviewRef, workshopsRef, oneToOneRef, oradoresRef, titlesRef]);

    const addToSpeedInterviewRef = el => {
        if (el && !speedInterviewRef.current.includes(el)) {
            speedInterviewRef.current.push(el);
        }
    };

    const addToOneToOneRef = el => {
        if (el && !oneToOneRef.current.includes(el)) {
            oneToOneRef.current.push(el);
        }
    };

    const addToWorkshopsRef = el => {
        if (el && !workshopsRef.current.includes(el)) {
            workshopsRef.current.push(el);
        }
    };

    const addToOradoresRef = el => {
        if (el && !oradoresRef.current.includes(el)) {
            oradoresRef.current.push(el);
        }
    };

    const addToTitlesRef = el => {
        if (el && !titlesRef.current.includes(el)) {
            titlesRef.current.push(el);
        }
    };

    return (
        <div className="Home">
            <section id="about-section" className="hero is-large">
                <div className="container about-box">
                    <div className="columns">
                        <div className="column about-column">
                            <h1
                                className="title about-title"
                                ref={addToTitlesRef}
                            >
                                Speed Interview
                            </h1>
                            <p className="about-text-padded">
                                <div ref={addToSpeedInterviewRef}>
                                    Gostarias de começar a preparar-te para a
                                    tua primeira ou próxima entrevista de
                                    emprego? Então esta é a atividade ideal para
                                    ti!
                                    <br />
                                    <br />
                                </div>
                                <div ref={addToSpeedInterviewRef}>
                                    Nesta atividade terás a oportunidade de
                                    fazeres um pitch individual, com duração
                                    máxima de 3 minutos, perante um grupo com
                                    3/4 empresas ansiosas por te conhecer, que
                                    te vão dar um feedback em relação à maneira
                                    como abordaste o teu pitch, o conteúdo
                                    escolhido e a tua postura! A duração total
                                    do pitch + feedback será de 10 minutos.
                                    <br />
                                    <br />
                                </div>
                                <div ref={addToSpeedInterviewRef}>
                                    Este ano temos uma novidade para ti! Após o
                                    teu pitch tens a possibilidade de ser
                                    escolhido para a atividade One-to-one!
                                    <br />
                                    <br />
                                </div>
                                <div ref={addToSpeedInterviewRef}>
                                    Inscreve-te nesta oportunidade única{" "}
                                    <a
                                        className="link-highlighted is-paddingless"
                                        href="https://docs.google.com/forms/d/e/1FAIpQLSe9-Zbknyp7vz_vLtn5AXgcIYd1V6me8vvN8N-M-vQPGYYhAQ/viewform?usp=sf_link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        aqui!
                                    </a>
                                    <br />
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about-section" className="hero is-large">
                <div className="container about-box">
                    <div className="columns">
                        <div className="column about-column">
                            <h1
                                className="title about-title"
                                ref={addToTitlesRef}
                            >
                                One-to-one:
                            </h1>
                            <p className="about-text-padded">
                                <div ref={addToOneToOneRef}>
                                    Esta atividade tem como objetivo aproximar
                                    ainda mais as empresas dos alunos, sendo uma
                                    segunda fase da “Speed Interview”. Após o
                                    teu pitch terás a oportunidade de ser
                                    selecionado por uma das empresas para uma
                                    conversa de 15 minutos “One-to-one”.
                                    <br />
                                    <br />
                                </div>
                                <div ref={addToOneToOneRef}>
                                    Não percas esta oportunidade única!
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about-section" className="hero is-large">
                <div className="container about-box">
                    <div className="columns">
                        <div className="column about-column">
                            <h1
                                className="title about-title"
                                ref={addToTitlesRef}
                            >
                                Workshops
                            </h1>

                            {/* <p
                                className="about-text-padded"
                                ref={addToWorkshopsRef}
                            >
                                Em construção
                                <br />
                            </p> */}
                            <div ref={addToWorkshopsRef}>
                                <span className="small-title">
                                    Inscreve-te nos workshops{" "}
                                    <a
                                        className="link-highlighted is-paddingless"
                                        href="https://docs.google.com/forms/d/e/1FAIpQLSfqIuzuip1brgqxMHA35jBQNz1aixf5GqubkWd4IjL-OrE85Q/viewform"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        aqui!
                                    </a>
                                    <br />
                                    <br />
                                </span>
                            </div>
                            <div ref={addToWorkshopsRef}>
                                <span className="title small-title">
                                    &#9658;"Como fazer um pitch?" | Magma | Dia
                                    14 | 12:00 - 12:30
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    Nunca foste a uma entrevista de emprego?
                                    Queres saber a atitude e o discurso que
                                    valorizam, bem como a informação que deves
                                    priorizar? Queres ir a uma Speed Interview,
                                    mas nunca fizeste um pitch? Por todos estes
                                    motivos, este workshop é para ti!
                                    <br />
                                </p>
                            </div>
                            <div ref={addToWorkshopsRef}>
                                <span className="title small-title">
                                    &#9658;"Como fazer um CV" | Deloitte | Dia
                                    15 | 10:30 - 11:30 <br />
                                </span>
                                <p className="about-text-padded">
                                    Cada vez ouves mais que um CV curto e
                                    conciso é fulcral, mas não sabes que tipo de
                                    informação é valorizada pelas empresas?
                                    Então este workshop vai esclarecer-te!
                                    <br />
                                </p>
                            </div>
                            <div ref={addToWorkshopsRef}>
                                <span className="title small-title">
                                    &#9658;"Drive - O mundo do IoT" | Luís
                                    Colaço @ Fidelidade | Dia 16 | 14:00 <br />
                                </span>
                                <p className="about-text-padded">
                                    Queres saber mais sobre o crescente
                                    desenvolvimento do mundo do IoT? Este é o
                                    workshop para ti!
                                    <br />
                                </p>
                            </div>
                            <div ref={addToWorkshopsRef}>
                                <span className="title small-title">
                                    &#9658;"Criação de fluxo na produção" |
                                    Kaizen Institute | Dia 17 | 10:00
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    Ao longo da história, a Kaizen tem vindo a
                                    marcar a sua posição enquanto líderes de
                                    mercado na prestação de serviços de
                                    consultoria e formação para organizações de
                                    todo o mundo, nos domínios KAIZEN™, Gestão
                                    Lean e Melhoria Contínua. A sua Missão
                                    consiste em ajudar os líderes a alcançar
                                    sonhos de melhoria de desempenho e a
                                    construir uma cultura KAIZEN sustentável.
                                    Vem aprender sobre a criação de fluxo. Um
                                    workshop imperdível!
                                    <br />
                                </p>
                            </div>
                            <div ref={addToWorkshopsRef}>
                                <span className="title small-title">
                                    &#9658;"Transforming a complex Front-End
                                    into an unique system" | VTRXM | Dia 17 |
                                    12:00 <br />
                                </span>
                                <p className="about-text-padded">
                                    “Transforming a complex Front-End into an
                                    easy, intuitive and unique system using a
                                    set of in-house developed tools.” Tens
                                    curiosidade sobre o impacto da usabilidade
                                    na criação de soluções hoje em dia? Não
                                    percas este workshop!
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about-section" className="hero is-large">
                <div className="container about-box">
                    <div className="columns">
                        <div className="column about-column">
                            <h1
                                className="title about-title"
                                ref={addToTitlesRef}
                            >
                                Oradores
                            </h1>

                            {/* <p className="about-text-padded" ref={addToOradoresRef}>
                                Em construção
                                <br />
                            </p> */}
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; Design do primeiro avião elétrico |
                                    Rui Marcelino | Dia 14 - 15:00 <br />
                                </span>
                                <p className="about-text-padded">
                                    É fundador, CEO e Design Manager da
                                    Almadesign desde 1997, onde acompanhou mais
                                    de 800 projectos nas áreas do design de
                                    transportes e design de produtos. Com a
                                    Almadesign, recebeu algumas dezenas de
                                    prémios nacionais e internacionais. Nos
                                    últimos 15 anos, participou na dinamização
                                    de consórcios nacionais e europeus de R&D
                                    nas área Aeronáutica, Náutica, Rodoviária,
                                    Ferroviária e Saúde.
                                    <br />
                                </p>
                            </div>
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; O futuro da Computação em Cloud |
                                    Vítor Rodrigues, Tiago Gonçalves, Ricardo
                                    Pires Silva e António Alves | Dia 14 - 16:00{" "}
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    António Alves é Portugal Cloud Systems
                                    Leader na Oracle, onde tem a
                                    responsabilidade de vendas das soluções de
                                    Engineered Systems e Cloud @ Customer.
                                    Ricardo Pires Silva é Executive Director da
                                    Microsoft Portugal. Tiago Gonçalves é
                                    cofundador da InnoWave Technologies. Vítor
                                    Rodrigues é CEO e fundador da Magic Beans.
                                    <br />
                                </p>
                            </div>
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; Do IST para desenhar o futuro na
                                    Unbabel | Paulo Dimas | Dia 15 - 11:00{" "}
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    Começou o seu percurso no Instituto Superior
                                    Técnico e foi na Unbabel que encontrou o
                                    lugar ideal inventar o futuro da
                                    tradução.Atualmente, Paulo Dimas é VP of
                                    Product Inovation e líder do Unbabel Labs, o
                                    departamento que está a inventar o futuro na
                                    Unbabel, a scaleup portuguesa que lidera um
                                    projeto que tem a ambição de criar o
                                    primeiro centro de inteligência artificial
                                    responsável em Portugal.
                                    <br />
                                </p>
                            </div>
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; Como conseguir o primeiro emprego? |
                                    Ricardo Nunes, Carolina Alves, Mariana Reis
                                    e Rita Lopes | Dia 15 - 14:30 <br />
                                </span>
                                <p className="about-text-padded">
                                    <br />
                                </p>
                            </div>
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; Empreendedorismo na área da
                                    Sustentabilidade | Tiago Bandeira | Dia 15 -
                                    16:00 <br />
                                </span>
                                <p className="about-text-padded">
                                    Licenciado em Gestão Empresarial, trabalhou
                                    10 anos na Bosch como Gestor Internacional
                                    de Produto e Gestor de Inovação. Saiu para
                                    fundar o seu primeiro projeto de
                                    empreendedorismo que tem como missão
                                    converter eletrodomésticos em aparelhos
                                    inteligentes, servindo como aliados na
                                    revolução das energias renováveis.
                                    <br />
                                </p>
                            </div>
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; Mulheres na Liderança | Catarina
                                    Peyroteo Salgueiro, Laura Carvalho Santos,
                                    Maria Domingas Carvalhosa e Sara do Ó | Dia
                                    16 - 10:00 <br />
                                </span>
                                <p className="about-text-padded">
                                    Catarina Peyroteo Salteiro é a Directora de
                                    Comunicação Global e Marca da Defined.ai,
                                    scale-up de dados para Inteligência
                                    Artificial. Laura Carvalho Santos é Mestre
                                    em Contabilidade Avançada e Fiscalidade.
                                    Maria Domingas Carvalhosa tem mais de 25
                                    anos de experiência no setor das Relações
                                    Públicas e da Comunicação.Sara do Ó é
                                    Founder & CEO do Grupo Your, Presidente do
                                    Conselho de Administração da Your Capital e
                                    Membro não executivo do Board da DFK
                                    Portugal.
                                    <br />
                                </p>
                            </div>
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; Crypto 101 | Pedro Cerdeira, Jorge
                                    Santos e Alessio Zazzarini | Dia 16 - 14:30{" "}
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    Alessio é um empreendedor focado em
                                    construir ecossistemas para web 3.0
                                    startups. Pedro Cerdeira é participante
                                    activo no ecossistema empreendedor,como
                                    business angel, mentor, advisor e consultor
                                    de startups e investidores, apoiando
                                    empresas a escalar e a desenvolver negócio
                                    internacional.
                                    <br />
                                </p>
                            </div>
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; Drones: novas alturas, novos
                                    horizontes | Ricardo Mendes | Dia 16 - 16:00{" "}
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    O Eng. Ricardo Mendes é co-fundador e CEO da
                                    TEKEVER, que actua nas áreas dos Sistemas
                                    Aéreos Não Tripulados (UAS), do Espaço e da
                                    Transformação Digital. A TEKEVER é hoje
                                    líder Europeu na área da Vigilância Marítima
                                    com recurso a UAS, levando a cabo centenas
                                    de missões e milhares de horas de vôo por
                                    ano em diversos países Europeus.
                                    <br />
                                </p>
                            </div>
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; The Next Step | Dia 17 - 14:30
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    Queres saber mais sobre a experiência de
                                    ex-alunos do teu curso que estão a seguir os
                                    mais diversos percursos profissionais? Este
                                    painel é para ti. Vem fazer todas as
                                    perguntas que tens em mente!
                                    <br />
                                </p>
                            </div>
                            <div ref={addToOradoresRef}>
                                <span className="title small-title">
                                    &#9658; E se eu criar a minha empresa? |
                                    Carlos Alves, José Paiva, Nuno Pitta e
                                    Manuel Tanger | Dia 17 - 16:00
                                    <br />
                                </span>
                                <p className="about-text-padded">
                                    Carlos Allves é co-founder e CEO da Bring
                                    Global. José Paiva é co-founder da
                                    Landing.jobs. Manuel Tanger é co-founder da
                                    Beta-i. Nuno Pitta é cofundador e Managing
                                    Partner da Timestamp IT Management
                                    Solutions.
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Speakers;
