/**
 * @flow
 */

// Imports
import type { Action } from 'redux';
import { actionTypes } from '../actions/user';
import State from '../types/user';

/**
 * Alert reducer function.
 *
 * @param state Current state.
 * @param action Action to process.
 * @returns {*} Next state.
 */
const userReducer = (state:? State = new State(), action:Action):State => {
    if (!(state instanceof State)) {
        return new State().mergeDeep(state);
    }

    switch (action.type) {
        case actionTypes.SET_TOKEN:
            return state.set('token', action.payload);

        case actionTypes.SET_ERROR:
            return state.set('error', action.payload);

        case actionTypes.SET_IS_SUBMITTING:
            return state.set('isSubmitting', action.payload);

        default:
            return state;
    }
};

export default userReducer;
